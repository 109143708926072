/**
* ábout view 
====================================== */

.carousel-container {
	position: relative;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 50% 50%;
}
#carousel-1 {
	transform: translate(13px, 44px) scale(0.9);
}
#carousel-2 {
	transform: translate(-32px, 74px) scale(0.7);
}
#carousel-3 {
	transform: translate(102px, 0) scale(0.9);
}

/**
* cute dog 
====================================== */
.btn,
.dog .tail,
.dog .chin,
.dog .tongue::before,
.dog .tongue::after,
.dog .mouth,
.dog .nose,
.dog .teardrop,
.dog .eyes,
.dog .face::before,
.dog .face::after,
.dog .ears::before,
.dog .ears::after,
.section-back.active {
	transition: all 0.2s ease;
}
.back-degrade {
	background: linear-gradient(to top, #aace63, white);
	/* z-index: -1; */
	transition: all 0.2s ease;
	/* opacity: 0; */
	width: 100%;
	height: 100%;
	position: absolute;
	margin-top: -80px;
}
.back-degrade2 {
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(to bottom, #151629, rgb(179, 179, 179));
	/* z-index: -2; */
	transition: all 0.2s ease;
	/* opacity: 0; */
	margin-top: -80px;
}
.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	/* overflow-y: hidden; */
}

.card-container {
	position: relative;
	width: 360px;
	height: auto;
	margin: auto;
	padding-top: 90px;
	border-radius: 3%;
	background: transparent;
	z-index: 0;
}
.card-container::before,
.card-container::after {
	content: '';
	position: absolute;
	height: 100%;
	margin: auto;
	left: 0;
	right: 0;
	border-radius: 3%;
	z-index: -1;
	background-color: transparent;
}
.card-container::before {
	top: 3%;
	width: 93%;
	background: transparent;
}
.card-container::after {
	top: 5.5%;
	width: 85%;
	background: transparent;
}
.dog .ground {
	position: relative;
	width: 100%;
	background: transparent;
}
.dog .ground .shadow-ground {
	position: absolute;
	top: -12px;
	left: 0;
	right: 0;
	bottom: 0;
	width: 302px;
	height: 18px;
	background: #d9d9d9;
	margin: 0 auto 0 auto;
	border-radius: 2540%;
	z-index: -1;
	transition: all 0.6s ease;
}
.dog .head,
.dog .body {
	position: relative;
	width: 115px;
}
.dog .head {
	height: 115px;
	border-radius: 50% 50% 0 0;
	margin: 0 auto;
}
.dog .ears {
	position: relative;
	top: -14%;
	width: 100%;
}
/* 643b19
CD7933 */
.dog .ears::before {
	content: '';
	position: absolute;
	top: 0;
	width: 35px;
	height: 70px;
	background: #643b19;
	border-top: 11px solid #643b19;
	border-left: 7px solid #643b19;
	border-right: 7px solid #643b19;
}
.dog .ears::after {
	content: '';
	position: absolute;
	top: 0;
	width: 35px;
	height: 70px;
	background: #643b19;
	border-top: 11px solid #cd7933;
	border-left: 7px solid #cd7933;
	border-right: 7px solid #cd7933;
}
.dog .ears::before {
	left: 0;
	border-radius: 50% 45% 0 0;
}
.dog .ears::after {
	right: 0;
	border-radius: 45% 50% 0 0;
}
.dog .face {
	position: absolute;
	background: #cd7933;
	width: 100%;
	height: 100%;
	border-radius: 50% 50% 0 0;
}
.dog .face::before,
.dog .face::after {
	content: '';
	display: block;
	margin: auto;
	background: #fefefe;
}
.dog .face::before {
	width: 15px;
	height: 35px;
	margin-top: 24px;
	border-radius: 20px 20px 0 0;
}
.dog .face::after {
	position: absolute;
	bottom: -1px;
	left: 0;
	right: 0;
	width: 60px;
	height: 65px;
	border-radius: 45% 45% 0 0;
}
.dog .eyes {
	position: relative;
	top: 29%;
	text-align: center;
}
.dog .eyes::before,
.dog .eyes::after {
	content: '';
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	background: #2c1a0b;
	margin: 0 14.5%;
}
.dog .teardrop {
	position: absolute;
	top: 125%;
	left: 19%;
	width: 15px;
	height: 15px;
	border-radius: 0 50% 50% 50%;
	transform: rotate(45deg);
	background: #279eff;
	visibility: hidden;
}
.dog .nose {
	position: relative;
	top: 35%;
	width: 16px;
	height: 8px;
	border-radius: 35px 35px 65px 65px;
	background: #2c1a0b;
	margin: auto;
}
.dog .mouth {
	position: relative;
	top: 34.5%;
	width: 4px;
	height: 6px;
	margin: 0 auto;
	text-align: center;
	background: #2c1a0b;
}
.dog .mouth::before,
.dog .mouth::after {
	content: '';
	position: absolute;
	top: -4px;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	border: 4px solid #2c1a0b;
	border-left-color: transparent;
	border-top-color: transparent;
	z-index: 2;
}
.dog .mouth::before {
	transform: translateX(-89%) rotate(45deg);
}
.dog .mouth::after {
	transform: translateX(-2px) rotate(45deg);
}
.dog .tongue {
	position: relative;
	z-index: 1;
}
.dog .tongue::before,
.dog .tongue::after {
	content: '';
	position: absolute;
}
.dog .tongue::before {
	top: 10px;
	left: -7px;
	width: 18px;
	height: 0;
	border-radius: 50%;
	background: #2c1a0b;
	z-index: -1;
}
.dog .tongue::after {
	top: 14px;
	left: -4px;
	width: 12px;
	height: 0;
	border-radius: 20px;
	background: #f5534f;
	z-index: 5;
}
.dog .chin {
	position: relative;
	top: 47.5%;
	margin: 0 auto;
	width: 12px;
	height: 12px;
	border-top: 10px solid #c7c6ca;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-radius: 2px;
	z-index: 0;
}
.dog .body {
	position: relative;
	height: 139px;
	margin: auto;
	z-index: 0;
}
.dog .body::before,
.dog .body::after {
	content: '';
	position: absolute;
	top: -1px;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	width: 100%;
	margin: auto;
	background: #cd7933;
}
.dog .body::after {
	top: -2px;
	bottom: -1px;
	width: 60px;
	background: #fefefe;
}
.dog .tail {
	position: absolute;
	left: -60%;
	bottom: 1px;
	background: #cd7933;
	width: 93px;
	height: 15px;
	transform: rotate(45deg);
	transform-origin: 100% 50%;
	border-radius: 25px 0 0 25px;
	z-index: -2;
	animation: movetail 0.2s linear infinite alternate forwards;
}
.dog .legs {
	position: absolute;
	bottom: 0;
	left: -10%;
	width: 120%;
	height: 15%;
	background: #cd7933;
	border-radius: 10px 10px 0 0;
}
.dog .legs::before,
.dog .legs::after {
	content: '';
	position: absolute;
	bottom: 1px;
	background: #643b19;
	z-index: -1;
}
.dog .legs::before {
	left: -7.5%;
	width: 115%;
	height: 55%;
	border-radius: 5px 5px 0 0;
}
.dog .legs::after {
	left: -3.5%;
	width: 107%;
	height: 250%;
	border-radius: 20px 20px 35px 35px;
}

.section-back {
	transition: 0.2s ease;
}
.back1 {
	width: 100%;
	height: 100%;
	background-color: #aace63;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	transition: all 0.2s ease;
}
.back2 {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(179, 179, 179);
	opacity: 0;
	transition: all 0.2s ease;
}
.wrapper.active .face::before {
	margin-top: 10px;
}
.wrapper.active .face::after {
	height: 85px;
}
.wrapper.active .eyes {
	top: 17%;
}
.wrapper.active .eyes::before,
.wrapper.active .eyes::after {
	width: 16px;
	height: 16px;
	margin: 0px 11%;
	border-radius: 50%;
	border: 4px solid #2c1a0b;
	background: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent;
	transform: rotate(30deg);
}
.wrapper.active .eyes::after {
	transform: rotate(50deg);
}
.wrapper.active .nose {
	top: 18%;
}
.wrapper.active .mouth {
	top: 16.5%;
}
.wrapper.active .tongue::before {
	height: 12px;
}
.wrapper.active .tongue::after {
	height: 24px;
	-webkit-animation: movetongue 0.1s linear 0.35s infinite alternate forwards;
	animation: movetongue 0.1s linear 0.35s infinite alternate forwards;
}
.wrapper.active .chin {
	top: 34%;
}
.wrapper.active .tail {
	-webkit-animation: movetail 0.08s linear infinite alternate forwards;
	animation: movetail 0.08s linear infinite alternate forwards;
}

/* .wrapper.deactivate {
	background: #747499;
} */
.wrapper.deactivate .ears::before {
	transform: rotate(-50deg) translate(-7px, 2px);
}
.wrapper.deactivate .ears::after {
	transform: rotate(50deg) translate(7px, 2px);
}
.wrapper.deactivate .face::before {
	margin-top: 28px;
}
.wrapper.deactivate .face::after {
	height: 55px;
}
.wrapper.deactivate .eyes {
	top: 38%;
}
.wrapper.deactivate .eyes::before,
.wrapper.deactivate .eyes::after {
	width: 18px;
	height: 5px;
	margin: 0px 12.5%;
	transform: rotate(-37.5deg);
	border-radius: 20px;
}
.wrapper.deactivate .eyes::after {
	transform: rotate(37.5deg);
}
.wrapper.deactivate .teardrop {
	-webkit-animation: cry 0.1s ease-in 0.25s forwards;
	animation: cry 0.1s ease-in 0.25s forwards;
}
.wrapper.deactivate .nose {
	top: 44%;
}
.wrapper.deactivate .mouth {
	top: 42%;
}
.wrapper.deactivate .chin {
	top: 52%;
}
.wrapper.deactivate .tail {
	animation: none;
	transition: all 0.2s ease;
	transform: rotate(0);
}
/**
* loader 
====================================== */
.pawn-container {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: #fefefe;
	z-index: 9999999999;
	color: #ff4880;
	display: flex;
	justify-content: center;
	align-items: center;
	/* display: none; */
}
.pawn-container .pawn-loader {
	transform: rotate(90deg) translate(0, 2%);
	font-size: 20px;
}
.pawn-loader .paw {
	width: 1em;
	height: 1em;
	opacity: 0;
	margin: 5px;
	-webkit-animation: 2050ms pawAnimation ease-in-out infinite;
	animation: 2050ms pawAnimation ease-in-out infinite;
}
.pawn-loader .paw svg {
	width: 100%;
	height: 100%;
}
.pawn-loader .paw .icon {
	fill: currentColor;
}
.pawn-loader .paw:nth-child(odd) {
	transform: rotate(-10deg);
}
.pawn-loader .paw:nth-child(even) {
	transform: rotate(10deg) translate(125%, 0);
}
.pawn-loader .paw:nth-child(1) {
	/* -webkit-animation-delay: 0.5s;
	animation-delay: 0.5s; */
}
.pawn-loader .paw:nth-child(3) {
	-webkit-animation-delay: 0.75s;
	animation-delay: 0.75s;
}
.pawn-loader .paw:nth-child(4) {
	-webkit-animation-delay: 0.35s;
	animation-delay: 0.35s;
}
.pawn-loader .paw:nth-child(5) {
	-webkit-animation-delay: 0.65s;
	animation-delay: 0.65s;
}
.pawn-loader .paw:nth-child(6) {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.pawn-loader .paw:nth-child(7) {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}
.pawn-loader .paw:nth-child(8) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.pawn-loader .paw:nth-child(9) {
	-webkit-animation-delay: 0.15s;
	animation-delay: 0.15s;
}
.pawn-loader .paw:nth-child(10) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.pawn-loader .paw .icon {
	fill: currentColor;
}
.pawn-loader .house .house-icon {
	fill: currentColor;
	transform: rotate(-90deg);
}
.pawn-loader .house {
	transform: translate(-18%, 0);
	position: relative;
	margin-top: -20px;
	width: 3.3em;
	height: 3.3em;
	-webkit-animation: 2110ms rubberBandd ease-in-out infinite;
	animation: 2110ms rubberBandd ease-in-out infinite;
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.8s;
}
.pawn-loader .house svg {
	width: 100%;
	height: 100%;
}
.pawn-loader .heart svg {
	width: 100%;
	height: 100%;
}
.pawn-loader .heart .heart-icon {
	fill: currentColor;
	transform: rotate(-90deg);
}
.pawn-loader .heart {
	transform: translate(4%, 67%) scale(0.7);
	position: relative;
	margin-top: -20px;
	width: 3.3em;
	height: 3.3em;
	-webkit-animation: 2110ms fadeTop ease-in-out infinite;
	animation: 2110ms fadeTop ease-in-out infinite;
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.8s;
}
@keyframes rubberBandd {
	from {
		transform: scale3d(1, 1, 1) translate(-18%, 0);
	}

	5% {
		transform: scale3d(1.25, 0.75, 1) translate(-18%, 0);
	}

	10% {
		transform: scale3d(0.75, 1.25, 1) translate(-18%, 0);
	}

	20% {
		transform: scale3d(1.15, 0.85, 1) translate(-18%, 0);
	}

	30% {
		transform: scale3d(0.95, 1.05, 1) translate(-18%, 0);
	}

	40% {
		transform: scale3d(1.05, 0.95, 1) translate(-18%, 0);
	}
	50% {
		transform: scale3d(1, 1, 1) translate(-18%, 0);
	}
	100% {
		transform: scale3d(1, 1, 1) translate(-18%, 0);
	}
}
@keyframes fadeTop {
	from {
		transform: translate(4%, 67%) scale(0.7);
	}
	40% {
		transform: translate(-70%, 67%);
	}
	50% {
		transform: translate(-70%, 67%) scale(1);
	}
	100% {
		transform: translate(-70%, 67%) scale(1);
	}
}
@-webkit-keyframes pawAnimation {
	0% {
		opacity: 1;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes pawAnimation {
	0% {
		opacity: 1;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

/**
* animations 
====================================== */

@-webkit-keyframes movetongue {
	100% {
		height: 27px;
	}
}

@keyframes movetongue {
	100% {
		height: 27px;
	}
}
@-webkit-keyframes movetail {
	0% {
		transform: rotate(37deg);
	}
	100% {
		transform: rotate(52deg);
	}
}
@keyframes movetail {
	0% {
		transform: rotate(37deg);
	}
	100% {
		transform: rotate(52deg);
	}
}
@-webkit-keyframes cry {
	100% {
		visibility: visible;
	}
}
@keyframes cry {
	100% {
		visibility: visible;
	}
}
/**
* screen size 
====================================== */
@media screen and (max-width: 1199px) {
	.card-container {
		padding-top: 25px;
	}
}
