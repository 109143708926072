/***************************************************************************************************************
||||||||||||||||||||||||||       MASTER STYLESHEET FOR CAREPRESS           |||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
||||||||||||||||||||||||||                TABLE OF CONTENT               |||||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
****************************************************************************************************************

01. Imported styles
02. Flaticon reset styles
03. Header styles
04. Mainmenu styles
05. Rev_slider_wrapper styles
66. Call to action area Style
07. Categories area style
08. Fact counter area style
09. Latest Blog area style
10. Latest blog area style 
11. Testimonial area style
12. Brand area style
13. Single service style
14. Pricing table area style
15. About page style
16. Account page style
17. 404 page style style
18. Blog page style
19. Blog single style
20. Contact page style

****************************************************************************************************************
||||||||||||||||||||||||||||            End TABLE OF CONTENT                ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

@import url(../css/animate.css);
@import url(../css/bootstrap-select.min.css);
@import url(../css/icomoon.css);
@import url(../css/jquery.bootstrap-touchspin.css);
@import url(../css/jquery.fancybox.min.css);
@import url(../css/jquery.mCustomScrollbar.min.css);
@import url(../css/nouislider.css);
@import url(../css/nouislider.pips.css);
@import url(../css/polyglot-language-switcher.css);
@import url(../css/timePicker.css);
@import url(../css/slick.css);
@import url(../css/jquery.bxslider.css);
@import url(../css/jquery-ui.css);
@import url(../css/tinyscrollbar.css);
@import url(../css/twentytwenty.css);

/*==============================================
   Base Css
===============================================*/
* {
	margin: 0px;
	padding: 0px;
	border: none;
	outline: none;
	font-size: 100%;
}
html,
body {
	height: 100%;
}
body {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
}
button:focus {
	outline: none;
}
button {
	cursor: pointer;
	border: none;
	background: transparent;
	padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #393d72;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
	margin: 0;
}
h3 {
	font-size: 18px;
	line-height: 30px;
	font-weight: 700;
}
h4 {
}
a,
a:hover,
a:active,
a:focus {
	text-decoration: none;
	outline: none;
	border: none;
}
img.lazy-image {
	background: url(../images/icon/image-bg.svg) center center no-repeat;
}
img.lazy-image.loaded {
	background-image: none;
}
.owl-theme img.lazy-image.loaded {
	height: auto;
	opacity: 1;
}

/* Tiny Scrollbar */
#scrollbar1 {
	width: 500px;
	margin: 0;
}
#scrollbar1 .viewport {
	position: relative;
	overflow: hidden;
	width: 500px;
	height: 870px;
}
#scrollbar1 .overview {
	list-style: none;
	position: absolute;
	left: 0;
	top: 0;
	padding: 0;
	margin: 0;
	max-width: 500px;
	width: 100%;
	height: 100%;
}

#scrollbar1 .scrollbar {
	position: absolute;
	top: 0;
	right: -30px;
	background: #02c18d;
	width: 10px;
}
#scrollbar1 .track {
	background: url(../images/icon/bg-scrollbar-trackend-y.png) no-repeat 0 100%;
	height: 100%;
	width: 10px;
	position: relative;
	padding: 0;
}

#scrollbar1 .thumb {
	background: url(../images/icon/bg-scrollbar-thumb-y.png) no-repeat 50% 100%;
	background-image: none;
	height: 20px;
	width: 10px;
	cursor: pointer;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0px;
	opacity: 1;
}
#scrollbar1 .thumb .end {
	background: #353742 no-repeat 50% 0;
	overflow: hidden;
	height: 120px;
	width: 10px;
}
#scrollbar1 .disable {
	display: none;
}
.rounded {
	border-radius: 0.7rem !important;
}
.noSelect {
	user-select: none;
	-o-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

.fix {
	position: relative;
	display: block;
	overflow: hidden;
}
img {
	max-width: 100%;
	height: auto;
	transition-delay: 0.1s;
	transition-timing-function: ease-in-out;
	transition-duration: 0.7s;
	transition-property: all;
}
.map-data {
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.8em;
	padding: 5px 10px 5px;
}
.map-data a {
	color: #0b59d9;
	display: block;
}
.map-data h6 {
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 5px;
	color: #121212;
}

i {
	font-style: normal;
}
ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}
ol,
li {
	margin: 0;
	padding: 0;
}
.clr1 {
	color: #f36727;
}
.bgclr1 {
	background: #f36727;
}

.auto-container {
	position: static;
	max-width: 1200px;
	padding: 0px 15px;
	margin: 0 auto;
}

.btn-one {
	position: relative;
	display: inline-block;
	padding-left: 40px;
	padding-right: 40px;
	color: #ffffff;
	font-size: 16px;
	line-height: 50px;
	font-weight: 700;
	text-transform: capitalize;
	border-radius: 10px;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
	font-family: 'Poppins', sans-serif;
}
.btn-one:after {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	content: '';
	border-radius: 10px;
	transform: scaleY(0) rotateX(0deg);
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.btn-one:hover:after {
	transform: scaleY(1) rotateX(0deg);
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.btn-one .txt {
	position: relative;
	display: flex;
	align-items: center;
	z-index: 1;
}
.btn-one:hover,
.btn-one:focus {
	color: #393d72;
}
.btn-one.style2 {
	color: #ffffff;
}
.btn-one.style2:hover:after {
	transform: scaleY(1) rotateX(0deg);
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}

.sec-title {
	position: relative;
	display: block;
	padding-bottom: 48px;
}
.sec-title .icon {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 40px;
	background: #ffffff;
	border-radius: 10px;
	text-align: center;
	font-size: 18px;
	line-height: 40px;
}

.sec-title h2 {
	color: #393d72;
	font-size: 40px;
	line-height: 1.1em;
	font-weight: 700;
	text-transform: capitalize;
	margin: 11px 0 0;
}
.sec-title h2 .round-box {
	position: relative;
	display: inline-block;
	width: 13px;
	height: 13px;
	border-radius: 50%;
}
.sec-title h5 {
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
}
.sec-title h5 span {
	position: relative;
	display: inline-block;
	padding: 0 15px;
}

.parallax-bg-one {
	background-attachment: fixed;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	z-index: 1;
}
.parallax-bg-one::before {
	background: rgba(18, 32, 0, 0.9) none repeat scroll 0 0;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}

/*___ owl Nav Dot Style _____*/
.owl-theme .owl-prev span,
.owl-theme .owl-next span {
	display: block;
}

.owl-nav-style-one {
}
.owl-nav-style-one.owl-theme .owl-prev span,
.owl-nav-style-one.owl-theme .owl-next span {
	position: relative;
	display: block;
}
.owl-nav-style-one .owl-controls {
}
.owl-nav-style-one.owl-theme .owl-stage-outer {
	position: relative;
	display: block;
	padding-top: 0px;
	padding-bottom: 0px;
}
.owl-nav-style-one.owl-theme .owl-nav {
	position: absolute;
	top: -140px;
	right: 0;
	z-index: 10;
	display: block;
}
.owl-nav-style-one.owl-theme .owl-nav [class*='owl-'] {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 60px;
	border: 2px solid;
	border-radius: 15px;
	color: #ffffff;
	font-size: 20px;
	font-weight: 400;
	line-height: 56px;
	margin: 0 0 0 0px;
	padding: 0;
	transition: all 700ms ease 0s;
}
.correction,
.owl-nav {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}
.owl-nav-style-one.owl-theme .owl-nav .owl-prev {
	transform: rotate(90deg);
}
.owl-nav-style-one.owl-theme .owl-nav .owl-next {
	margin-left: 20px;
	transform: rotate(-90deg);
}

.owl-nav-style-two {
}
.owl-nav-style-two .owl-controls {
}
.owl-nav-style-two.owl-theme .owl-nav [class*='owl-'] {
	background: #fff none repeat scroll 0 0;
	border-radius: 0%;
	color: #3740b0;
	font-size: 24px;
	font-weight: 700;
	height: 50px;
	line-height: 50px;
	margin: 0 0 0 0px;
	padding: 0;
	width: 50px;
	transition: all 700ms ease 0s;
}
.owl-nav-style-two.owl-theme .owl-nav .owl-next {
	margin-left: 0px;
}
.owl-nav-style-two.owl-theme .owl-nav [class*='owl-']:hover {
	color: #ffffff;
	background: #3740b0;
}

.owl-carousel.owl-dot-style1 .owl-stage-outer {
	position: relative;
	display: block;
	padding-top: 0px;
	padding-bottom: 60px;
}
.owl-carousel.owl-dot-style1 .owl-dots {
	position: relative;
	line-height: 8px;
	height: 8px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	text-align: center;
}
.owl-carousel.owl-dot-style1 .owl-dots .owl-dot {
	position: relative;
	display: inline-block;
	width: 10px;
	height: 4px;
	border: 0px solid transparent;
	background: #031f4b;
	margin: 0px 5px;
	padding: 0px;
	border-radius: 0%;
	transition: all 100ms linear;
	transition-delay: 0.1s;
}
.owl-carousel.owl-dot-style1 .owl-dots .owl-dot span {
	display: none;
}
.owl-carousel.owl-dot-style1 .owl-dots .owl-dot.active {
	background: #0098ff;
	width: 20px;
	height: 8px;
}

/* Accordion Box Css */
.accordion-box {
	position: relative;
	display: block;
}
.accordion-box .accordion {
	position: relative;
	display: block;
	background: #f4f2ef;
	border: 0px solid #e1e1e1;
	border-radius: 15px;
	margin-bottom: 15px;
}
.accordion-box .accordion.mar0 {
	margin: 0;
}
.accordion-box .accordion .accord-btn {
	position: relative;
	display: block;
	cursor: pointer;
	padding-left: 90px;
	padding-right: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
	transition: all 500ms ease;
}
.accordion-box .accordion .accord-btn h4 {
	color: #393d72;
	font-size: 18px;
	line-height: 30px;
	font-weight: 700;
	transition: all 500ms ease;
}
.accordion-box .accordion .accord-btn::after {
	font-family: 'icomoon' !important;
	position: absolute;
	content: '\e903';
	top: 50%;
	left: 30px;
	width: 40px;
	height: 40px;
	background: #ffffff;
	border-radius: 10px;
	font-size: 12px;
	line-height: 40px;
	font-weight: 600;
	text-align: center;
	transform: translateY(-50%);
	transition: all 500ms ease 0s;
}
.accordion-box .accordion .accord-btn.active {
	background: #f4f2ef;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.accordion-box .accordion .accord-btn.active:after {
	content: '\e904';
}

.accordion-box .accordion .accord-content {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding-top: 0px;
	padding-bottom: 23px;
	padding-left: 30px;
	padding-right: 30px;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	display: none;
}
.accordion-box .accordion .accord-content.collapsed {
	display: block;
}
.accordion-box .accordion .accord-content p {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	margin: 0;
}

.accordion-box.style2 .accordion {
	background: #ffffff;
}
.accordion-box.style2 .accordion .accord-btn::after {
	background: #f4f2ef;
}
.accordion-box.style2 .accordion .accord-btn.active {
	background: #ffffff;
}
.accordion-box.style2 .accordion .accord-content {
	background: #ffffff;
}

@keyframes pulse {
	50% {
		box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(238, 238, 238, 0.3);
	}
}

@keyframes pulse2 {
	50% {
		box-shadow: 0 0 0 5px rgba(255, 231, 1, 0.1), 0 0 0 20px rgba(255, 231, 1, 0.3);
	}
}

.rating-box {
	position: relative;
	display: block;
	overflow: hidden;
}
.rating-box ul {
	overflow: hidden;
}
.rating-box ul li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 5px;
}
.rating-box ul li:last-child {
	margin-right: 0;
}
.rating-box ul li a {
	color: #fcbc38;
	font-size: 20px;
	font-weight: 400;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.boxed_wrapper {
	position: relative;
	overflow: hidden;
	background: #ffffff none repeat scroll 0 0;
	margin: 0 auto;
	width: 100%;
	min-width: 320px;
	min-height: 400px;
}

/*=============== Boxed-Layout =============*/
.boxed_wrapper_box_page {
	position: relative;
	overflow-x: hidden;
	margin: 0 auto;
	width: 100%;
	min-width: 300px;
}
.body_switcher {
	position: fixed;
	top: 150px;
	z-index: 9999999;
	left: -200px;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.body_switcher .body_switch_btn {
	position: absolute;
	right: -50px;
	z-index: -1;
}
.body_switcher .body_switch_btn button {
	width: 50px;
	height: 50px;
	color: #ffffff;
	font-size: 24px;
	line-height: 50px;
	background: rgba(0, 0, 0, 0.8);
	cursor: pointer;
	transition: all 500ms ease;
}

.body_switcher .boxed_switch_menu {
	position: relative;
	background: #fff;
	width: 200px;
	padding: 25px 15px 20px;
	text-align: center;
	box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
}
.body_switcher .boxed_switch_menu h5 {
	color: #222222;
	font-size: 20px;
	line-height: 24px;
	font-weight: 600;
	margin-bottom: 0px;
}
.body_switcher.switcher-show {
	left: 0;
}
.body_switcher .boxed_switch_menu .switch_body .box {
	cursor: pointer;
	display: inline-block;
	text-align: center;
	margin-top: 15px;
}
.body_switcher .boxed_switch_menu .switch_body .box > div {
	width: 80px;
	height: 50px;
	background: #fff;
	border: 2px solid #999;
}
.body_switcher .boxed_switch_menu .switch_body .box > div > span {
	display: block;
	width: 60px;
	height: 100%;
	margin: 0 auto;
	background: #fff;
	border-left: 2px solid #e5e5e5;
	border-right: 2px solid #e5e5e5;
}
.body_switcher .boxed_switch_menu .switch_body .box p {
	position: relative;
	display: block;
	color: #999;
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
	margin: 7px 0 0;
}
.main_page.active_boxlayout {
	max-width: 1550px;
	width: 100%;
	min-width: 300px;
	margin: 0 auto;
	background: url(../images/pattern/body-pattern.png) #f8f8f8;
	background-attachment: fixed;
	background-repeat: repeat;
	background-position: center;
	overflow-x: hidden;
}

/*=============== Color-Layout =============*/
.switcher {
	position: fixed;
	top: 220px;
	z-index: 99;
}
.switcher .switch_btn {
	position: absolute;
	top: 0px;
	right: -50px;
	z-index: -1;
}
.switcher .switch_btn button {
	width: 50px;
	height: 50px;
	color: #fff;
	line-height: 50px;
	cursor: pointer;
	border-radius: 0;
	margin-left: 0;
	transition: all 500ms ease;
	background: rgba(255, 255, 255, 0.7);
}

.switcher .switch_menu {
	position: absolute;
	width: 180px;
	height: 30px;
	background: transparent;
	display: none;
	top: 0px;
	left: 60px;
	bottom: 0;
	margin: 10px 0;
}
.switcher #styleOptions li {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	margin-right: 3px;
}
.switcher #styleOptions li:last-child {
	margin-right: 0;
}
.switcher #styleOptions li a {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	box-shadow: 0 0 0 0px #fff;
}

.switcher #styleOptions li a.blue-color {
	background: #6377ee;
}
.switcher #styleOptions li a.pink-color {
	background: #ff6ec7;
}
.switcher #styleOptions li a.violet-color {
	background: #6936d8;
}
.switcher #styleOptions li a.crimson-color {
	background: #ba0913;
}
.switcher #styleOptions li a.orange-color {
	background: #ffa500;
}

/*________________Preloader_______________ */
.preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999999999999999999;
	background-color: #ffffff;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(../images/icon/preloader.gif);
}
.preloader.style-two {
	background-image: url(../images/icon/preloader-1.gif);
}

/*** Scroll To Top style ***/
.scroll-top {
	position: fixed;
	right: 15px;
	bottom: 20px;
	width: 55px;
	height: 55px;
	color: #ffffff;
	border-radius: 5px;
	font-size: 20px;
	line-height: 55px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	display: none;
	cursor: pointer;
	transition: all 200ms linear;
	transition-delay: 0.1s;
	z-index: 999999999;
}
.scroll-top span {
}
.scroll-top:after {
	position: absolute;
	content: '';
	top: 100%;
	left: 5%;
	height: 10px;
	width: 90%;
	opacity: 1;
	z-index: -1;
	background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
	background: -webkit-radial-gradient(center ellipse, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
	background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
.scroll-top:hover {
	color: #f8f8f8;
}

.scroll-top.style2 {
	position: absolute;
	left: 0;
	top: -20px;
	right: 0;
	width: 40px;
	height: 40px;
	margin: 0 auto;
	border-radius: 5px;
	background-color: #0098ff;
	border: none;
	text-align: center;
	animation: auto;
	transition: all 200ms linear;
	transition-delay: 0.1s;
	font-size: 20px;
	line-height: 40px;
	font-weight: 400;
}

/* Form validation styles */
input:focus,
textarea:focus,
select:focus {
	border-color: #43c3ea;
	outline: none;
}

#contact-form input[type='text'].error {
	border-color: red;
}
#contact-form input[type='email'].error {
	border-color: red;
}
#contact-form select.error {
	border-color: red;
}
#contact-form textarea.error {
	border-color: red;
}

/* Post pagination styles */
.post-pagination {
	position: relative;
	display: block;
}
.post-pagination.martop20 {
	margin-top: 20px;
}
.post-pagination li {
	position: relative;
	display: inline-block;
	margin: 0 3px;
}
.post-pagination li a {
	position: relative;
	display: block;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	border: 2px solid #eeeeee;
	color: #131313;
	font-size: 18px;
	line-height: 56px;
	font-weight: 600;
	transition: all 500ms ease 0s;
	font-family: 'Poppins', sans-serif;
}
.post-pagination li a i {
	color: #c1c1c1;
	font-size: 22px;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.post-pagination li.active a,
.post-pagination li:hover a {
	background: #fec727;
	border-color: #fec727;
}
.post-pagination li.active a i,
.post-pagination li:hover a i {
	color: #131313;
}

.post-pagination.style2 li a {
	border-radius: 0;
}

.secpd100-0 {
	padding: 100px 0;
}

/* Overlay styles */
.overlay-style-one {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition-delay: 0.1s;
	transition-timing-function: ease-in-out;
	transition-duration: 0.7s;
	transition-property: all;
	opacity: 0;
	z-index: 1;
}

.overlay-style-one.bg3 {
	background-color: rgba(0, 83, 153, 0.9);
}
.overlay-style-one .box {
	display: table;
	height: 100%;
	width: 100%;
}
.overlay-style-one .box .content {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}
.overlay-style-one .box .inner {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.overlay-style-two {
	position: absolute;
	left: 0px;
	bottom: 0px;
	right: 0px;
	top: 0px;
	z-index: 2;
	opacity: 0;
	transition: all 900ms ease;
}
.overlay-style-two:before {
	position: absolute;
	content: '';
	top: 0px;
	left: 0px;
	width: 100%;
	height: 50%;
	display: block;
	opacity: 0;
	text-align: center;
	transform: perspective(400px) rotateX(-90deg);
	transform-origin: top;
	transition: all 0.5s;
	background-color: rgba(18, 18, 18, 0.9);
}
.overlay-style-two:after {
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	width: 100%;
	height: 50%;
	display: block;
	opacity: 0;
	text-align: center;
	transform: perspective(400px) rotateX(90deg);
	transform-origin: bottom;
	transition: all 0.5s;
	background-color: rgba(18, 18, 18, 0.9);
}

.overlay-style1 {
	position: absolute;
	top: 0;
	left: -100%;
	bottom: 0;
	width: 100%;
	opacity: 0;
	transform-origin: top;
	transform-style: preserve-3d;
	transition: all 0.9s cubic-bezier(0.62, 0.21, 0.45, 1.22);
	z-index: 1;
}
.overlay-style1.bg1 {
	background-color: rgba(0, 0, 0, 0.7);
}
.overlay-style2 {
	position: absolute;
	top: 20px;
	left: 20px;
	bottom: 20px;
	right: 20px;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.7);
	transform: skew(0deg, 0deg) scale(1.2, 1.2);
	transition: all 0.9s cubic-bezier(0.62, 0.21, 0.45, 1.22);
	z-index: 1;
}

.review-box {
	position: relative;
	display: block;
	overflow: hidden;
	line-height: 16px;
}
.review-box ul {
	display: block;
	overflow: hidden;
}
.review-box ul li {
	display: inline-block;
	float: left;
	margin-right: 3px;
}
.review-box ul li:last-child {
	margin-right: 0px;
}
.review-box ul li i {
	color: #f6d000;
	font-size: 18px;
}

.social-links-style1 {
	position: relative;
	display: block;
	overflow: hidden;
}
.social-links-style1 li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 10px;
}
.social-links-style1 li:last-child {
	margin-right: 0px;
}
.social-links-style1 li a i {
	position: relative;
	display: block;
	background: #f4f5f7;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	color: #353742;
	font-size: 16px;
	font-weight: 400;
	line-height: 50px;
	text-align: center;
	transition: all 500ms ease;
}
.social-links-style1 li a:hover i {
	color: #ffffff;
	background: #02c18d;
}

.sociallinks-style-two {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 7px 0 8px;
}
.sociallinks-style-two li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 25px;
}
.sociallinks-style-two li:last-child {
	margin-right: 0px;
}
.sociallinks-style-two li a i {
	position: relative;
	display: block;
	color: #ffffff;
	font-size: 16px;
	transition: all 500ms ease 0s;
}
.sociallinks-style-two li a:hover i {
	color: #e4b33d;
}

/* Update header Style */
@keyframes menu_sticky {
	0% {
		margin-top: -90px;
	}
	50% {
		margin-top: -74px;
	}
	100% {
		margin-top: 0;
	}
}

/*** 
========================================
    Mobile Menu
========================================
***/
.nav-outer .mobile-nav-toggler {
	position: relative;
	display: none;
	float: right;
	cursor: pointer;
	padding: 20px 0;
}
.nav-outer .mobile-nav-toggler .inner {
	position: relative;
	display: block;
	padding: 3px 5px;
}

.mobile-menu {
	position: fixed;
	top: 0;
	right: 0;
	width: 300px;
	max-width: 100%;
	height: 100%;
	padding-right: 30px;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
}
.mobile-menu .menu-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(3, 13, 40, 0.9);
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	z-index: 1;
}
.mobile-menu-visible .mobile-menu .menu-backdrop {
	opacity: 0.7;
	visibility: visible;
	-webkit-transition: all 0.7s ease;
	-moz-transition: all 0.7s ease;
	-ms-transition: all 0.7s ease;
	-o-transition: all 0.7s ease;
	transition: all 0.7s ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}
.mobile-menu .mCSB_inside > .mCSB_container {
	margin-right: 5px;
}
.mobile-menu .navbar-collapse {
	display: block !important;
}

.mobile-menu .nav-logo {
	position: relative;
	padding: 30px 25px;
	text-align: left;
}
.mobile-menu .nav-logo a {
	position: relative;
	display: inline-block;
}

.mobile-menu-visible {
	overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
	opacity: 1;
	visibility: visible;
}
.mobile-menu .menu-box {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	background: #000000;
	padding: 0px 0px;
	z-index: 5;
	opacity: 0;
	visibility: hidden;
	border-radius: 0px;
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
}
.mobile-menu-visible .mobile-menu .menu-box {
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.7s ease;
	-moz-transition: all 0.7s ease;
	-ms-transition: all 0.7s ease;
	-o-transition: all 0.7s ease;
	transition: all 0.7s ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}
.mobile-menu .close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #ffffff;
	font-size: 20px;
	line-height: 30px;
	width: 24px;
	text-align: center;
	cursor: pointer;
	z-index: 10;
	-webkit-transition: all 0.9s ease;
	-moz-transition: all 0.9s ease;
	-ms-transition: all 0.9s ease;
	-o-transition: all 0.9s ease;
	transition: all 0.9s ease;
}
.mobile-menu-visible .mobile-menu .close-btn {
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
}
.mobile-menu .close-btn:hover {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.mobile-menu .navigation {
	position: relative;
	display: block;
	width: 100%;
	float: none;
}
.mobile-menu .navigation li {
	position: relative;
	display: block;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation:last-child {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li > ul > li:first-child {
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li > a {
	position: relative;
	display: block;
	padding: 10px 25px;
	color: #ffffff;
	font-size: 15px;
	line-height: 24px;
	font-weight: 600;
	text-transform: uppercase;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.mobile-menu .navigation li > a:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 0;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.mobile-menu .navigation li.current > a:before {
	height: 100%;
}

.mobile-menu .navigation li ul li > a {
	font-size: 15px;
	font-weight: 400;
	margin-left: 20px;
	text-transform: capitalize;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
	position: absolute;
	top: 6px;
	right: 6px;
	width: 32px;
	height: 32px;
	text-align: center;
	color: #ffffff;
	font-size: 16px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.1);
	cursor: pointer;
	border-radius: 2px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	z-index: 5;
}
.mobile-menu .navigation li.dropdown .dropdown-btn.open {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
	display: none;
}

.mobile-menu .social-links {
	position: relative;
	text-align: center;
	padding: 30px 25px;
}
.mobile-menu .social-links li {
	position: relative;
	display: inline-block;
	margin: 0px 5px 10px;
}
.mobile-menu .social-links li a {
	position: relative;
	color: #ffffff;
	font-size: 20px;
	line-height: 32px;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

/*==============================================
    Main Header Css        
===============================================*/
.main-header {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	margin: 0px;
	z-index: 999999;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}
.sticky-header {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	padding: 0px 0px;
	background: rgba(255, 255, 255, 0.97);
	opacity: 0;
	visibility: hidden;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	z-index: 0;
}
.fixed-header .sticky-header {
	visibility: visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	opacity: 1;
	z-index: 999999;
}
.sticky-header .logo {
	position: relative;
	display: block;
	padding: 17px 0 17px;
}
.sticky-header .logo a {
	position: relative;
	display: inline-block;
}
.sticky-header .main-menu .navigation > li {
}
.sticky-header .main-menu .navigation > li > a {
	padding: 20px 0;
}

.header-style-one {
}
.header-top {
	position: relative;
	display: block;
	padding: 4px 0 5px;
}
.header-top .outer-container {
	position: static;
	max-width: 1430px;
	padding: 0px 15px;
	margin: 0 auto;
}
.header-top .outer-box {
	position: relative;
	display: block;
}
.header-top-left {
	position: relative;
	display: block;
}

.header-contact-info {
	position: relative;
	display: block;
	overflow: hidden;
}
.header-contact-info ul {
	overflow: hidden;
}
.header-contact-info ul li {
	position: relative;
	display: inline-block;
	float: left;
	padding-right: 31px;
	margin-right: 30px;
	line-height: 30px;
}
.header-contact-info ul li:before {
	position: absolute;
	top: 4px;
	right: 0;
	bottom: 4px;
	width: 1px;
	background-color: rgba(255, 255, 255, 0.5);
	content: '';
}
.header-contact-info ul li:last-child:before {
	display: none;
}
.header-contact-info ul li:last-child {
	padding-right: 0;
	margin-right: 0;
}
.header-contact-info ul li span:before {
	position: relative;
	display: inline-block;
	top: 1px;
	padding-right: 10px;
	font-size: 16px;
	font-weight: 600;
}
.header-contact-info ul li a {
	color: #d3d3d3;
	font-size: 14px;
	font-weight: 700;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.header-top-right {
	position: relative;
	display: block;
}
.header-social-link {
	position: relative;
	display: block;
}
.header-social-link ul {
	overflow: hidden;
}
.header-social-link ul li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 20px;
	line-height: 30px;
}
.header-social-link ul li:last-child {
	margin-right: 0;
}
.header-social-link ul li a {
	color: #d3d3d3;
	font-size: 14px;
	transition: all 500ms ease;
}

.header {
	position: relative;
	display: block;
}
.header .outer-container {
	position: static;
	max-width: 1430px;
	padding: 0px 15px;
	margin: 0 auto;
}
.header .outer-box {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 0 30px;
	border-radius: 15px;
}
.header-left {
	position: relative;
	display: block;
}

.header-left .logo {
	position: relative;
	display: block;
	float: left;
	padding: 17px 0;
}
.header-left .logo a {
	position: relative;
	display: inline-block;
}

.header-left .nav-outer {
	position: relative;
	display: block;
	float: left;
	padding-left: 80px;
}
.main-menu.style1 {
}
.main-menu {
	position: relative;
	display: block;
	float: left;
}
.main-menu .navbar-collapse {
	padding: 0px;
	display: block !important;
}
.main-menu .navigation {
	position: relative;
	display: block;
}
.main-menu .navigation > li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 40px;
}
.main-menu .navigation > li:last-child {
	margin-right: 0;
}
.main-menu .navigation > li > a {
	position: relative;
	display: block;
	padding: 25px 0;
	color: #393d72;
	font-size: 16px;
	line-height: 30px;
	font-weight: 700;
	text-transform: capitalize;
	transition: all 500ms ease;
	opacity: 1;
	font-family: 'Poppins', sans-serif;
}
.main-menu .navigation > li.dropdown > a {
	padding-right: 15px;
}
.main-menu .navigation > li.dropdown > a:before {
	position: absolute;
	right: 0px;
	font-family: 'icomoon' !important;
	content: '\e903';
	line-height: 30px;
	font-size: 10px;
	font-weight: 400;
}

.main-menu .navigation > li > ul {
	position: absolute;
	top: 100%;
	left: 0;
	width: 270px;
	padding: 0px 0;
	opacity: 0;
	visibility: hidden;
	border-radius: 0;
	-moz-transform: translateY(30px);
	-webkit-transform: translateY(30px);
	-ms-transform: translateY(30px);
	-o-transform: translateY(30px);
	transform: translateY(30px);
	transition: all 100ms ease;
	z-index: 100;
}
.main-menu .navigation > li > ul:before {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	content: '';
	background: #ffffff;
	border-radius: 0;
	-webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	z-index: -1;
}
.main-menu .navigation > li.dropdown:hover > ul {
	opacity: 1;
	visibility: visible;
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}
.main-menu .navigation > li > ul > li {
	position: relative;
	display: block;
	padding: 0 20px;
	width: 100%;
}
.main-menu .navigation > li > ul > li > a {
	position: relative;
	display: block;
	border-bottom: 1px solid #e9e9e9;
	padding: 13px 0px 13px;
	color: #393d72;
	font-size: 15px;
	line-height: 24px;
	font-weight: 600;
	text-align: left;
	text-transform: capitalize;
	transition: all 500ms ease;
	font-family: 'Poppins', sans-serif;
}
.main-menu .navigation > li > ul > li:last-child > a {
	border: none;
}
.main-menu .navigation > li > ul > li > a:hover {
	padding-left: 15px;
}
.main-menu .navigation > li > ul > li > a:before {
	position: absolute;
	top: 0;
	left: 0px;
	bottom: 0;
	content: '';
	margin: 15px 0;
	width: 0px;
	opacity: 0;
	transition: all 500ms ease;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.main-menu .navigation > li > ul > li:hover > a:before {
	width: 5px;
}

.main-menu .navigation > li > ul > li.dropdown > a:after {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0;
	font-family: FontAwesome;
	content: '\f105';
	font-size: 14px;
	font-weight: 700;
	margin: 12px 0;
	text-align: right;
	z-index: 5;
}

.main-menu .navigation > li > ul > li:hover > a:before {
	opacity: 1;
}
.main-menu .navigation > li > ul > li > ul {
	position: absolute;
	top: 0%;
	left: 100%;
	width: 250px;
	padding: 0;
	display: none;
	background: #ffffff;
	border-radius: 0;
	-webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
	-moz-transform: translateY(30px);
	-webkit-transform: translateY(30px);
	-ms-transform: translateY(30px);
	-o-transform: translateY(30px);
	transform: translateY(30px);
	transition: all 100ms ease;
	z-index: 100;
}
.main-menu .navigation li > ul > li.dropdown:hover ul {
	opacity: 1;
	visibility: visible;
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}
.main-menu .navigation > li > ul > li > ul > li {
	position: relative;
	display: block;
	padding: 0 20px;
	width: 100%;
}
.main-menu .navigation > li > ul > li > ul > li > a {
	position: relative;
	display: block;
	border-bottom: 1px solid #e9e9e9;
	padding: 13px 0px 13px;
	color: #393d72;
	font-size: 15px;
	line-height: 24px;
	font-weight: 600;
	text-align: left;
	text-transform: capitalize;
	transition: all 500ms ease;
	font-family: 'Poppins', sans-serif;
}
.main-menu .navigation > li > ul > li > ul > li:last-child > a {
	border: none;
}
.main-menu .navigation > li > ul > li:hover > a {
	padding-left: 15px;
}
.main-menu .navigation > li > ul > li > ul > li > a:hover {
	padding-left: 15px;
}
.main-menu .navigation > li > ul > li > ul > li a:before {
	position: absolute;
	top: 0;
	left: 0px;
	bottom: 0;
	content: '';
	margin: 15px 0;
	width: 0px;
	transition: all 500ms ease;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.main-menu .navigation > li > ul > li > ul > li:hover > a:before {
	width: 5px;
}
/****
.main-menu .navigation> li> ul.megamenu {
    position: absolute;
    left: -232px;
    width: 700px;
}
.main-menu .navigation> li> ul.megamenu li {
    position: relative;
    max-width: 33.333333%;
    float: left;
}
***/
.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
	position: absolute;
	right: 0px;
	top: 0;
	width: 50px;
	height: 42px;
	border-left: 1px solid #04102a;
	text-align: center;
	font-size: 16px;
	line-height: 42px;
	color: #ffffff;
	cursor: pointer;
	display: none;
	z-index: 5;
}

.header-right {
	position: relative;
	display: block;
}
.header-right .outer-search-box-style1 {
	position: relative;
	display: block;
	float: right;
	padding: 10px 0;
	margin-right: 15px;
}
.outer-search-box-style1 {
	position: relative;
	display: inline-block;
	float: left;
}
.outer-search-box-style1 .seach-toggle {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	color: #242424;
	font-size: 16px;
	line-height: 38px;
	cursor: pointer;
	text-align: center;
	margin: 0;
	transition: all 500ms ease;
	border: 1px dashed transparent;
	border-radius: 50%;
}
.outer-search-box-style1 .search-box {
	position: absolute;
	top: 120%;
	right: 0%;
	background: rgba(0, 0, 0, 0.9);
	opacity: 0;
	padding: 20px;
	transition: all 500ms ease 0s;
	visibility: hidden;
	z-index: 100;
	margin-left: 0;
}
.outer-search-box-style1 .search-box.now-visible {
	top: 100%;
	opacity: 1;
	visibility: visible;
	z-index: 100;
}
.outer-search-box-style1 .search-box .form-group {
	position: relative;
	padding: 0px;
	margin: 0px;
	width: 100%;
	min-width: 250px;
}
.outer-search-box-style1 .search-box .form-group input[type='search'] {
	background: #ffffff none repeat scroll 0 0;
	border: 1px solid #1f1f23;
	color: #000;
	display: block;
	font-size: 13px;
	line-height: 24px;
	position: relative;
	transition: all 500ms ease 0s;
	width: 100%;
	height: 45px;
	padding-left: 15px;
	padding-right: 50px;
}
.outer-search-box-style1 .search-box .form-group input[type='search']:focus {
	border-color: #f5f5f5;
}
.outer-search-box-style1 .search-box .form-group button,
.outer-search-box-style1 .search-box .form-group input[type='submit'] {
	position: absolute;
	right: 0;
	top: 0;
	display: block;
	cursor: pointer;
	background: rgba(0, 0, 0, 0.95);
	color: #fff;
	font-size: 13px;
	height: 45px;
	line-height: 45px;
	width: 45px;
	transition: all 500ms ease 0s;
}
.outer-search-box-style1 .search-box .form-group button:hover,
.outer-search-box-style1 .search-box .form-group input:focus + button {
	color: #222222;
	background: #f5f5f5;
}

.header-right {
	position: relative;
	display: block;
}
.header-right .button {
	position: relative;
	display: block;
	float: left;
	padding: 15px 0;
	margin-right: 30px;
}
.header-right .button a {
	cursor: pointer;
}

.bar-box {
	position: relative;
	display: block;
	float: right;
	padding: 14px 0 0px;
}
.bar-box a {
	position: relative;
	display: inline-block;
}
.bar-box ul {
	display: block;
	overflow: hidden;
	margin-left: -5px;
	margin-right: -5px;
}
.bar-box ul li {
	position: relative;
	display: inline-block;
	float: left;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 0 5px 10px;
}
.bar-box ul li.red2 {
	background: #ffe2eb;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}

/*** 
=====================================================
	Header style Two Css
=====================================================
***/
.header-style-two {
}
.header-top.style2 {
	padding: 13px 0 14px;
	border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
.header-contact-info.style2 ul li span:before {
	color: #ffffff;
}
.header-contact-info.style2 ul li a {
	color: #ffffff;
}

.header-social-link.style2 ul li a {
	color: #ffffff;
}

.header.style2 .outer-box {
	background: transparent;
	padding: 0;
}
.main-menu.style2 .navigation > li > a {
	color: #ffffff;
}
.bar-box.style2 ul li {
	background: #ffffff;
}
.bar-box.style2 ul li.red2 {
	background-color: rgba(255, 255, 255, 0.2);
}
.bar-box.style2:hover ul li.red2 {
	background-color: rgba(255, 255, 255, 1);
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}

/*** 
=====================================================
	Main Slider style
=====================================================
***/
.main-slider {
	position: relative;
	display: block;
	background-attachment: fixed;
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	z-index: 10;
}
.main-slider.style1 {
	margin-top: 0px;
}
.main-slider .slide {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 283px 0px 215px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.main-slider .slide .image-layer {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	filter: contrast(1.2) saturate(1.5) brightness(0.7) blur(3px);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	transition: all 0.8s ease-in-out 0.4s;
}
.main-slider .slide .image-layer:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.2);
	content: '';
	z-index: -1;
}
.main-slider .active .slide .image-layer {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.main-slider .content {
	position: relative;
	z-index: 11;
}
.main-slider.style1 .content {
	position: relative;
	display: block;
	width: 100%;
}
.main-slider .auto-container {
	position: relative;
	overflow: hidden;
}
.main-slider .content h5 {
	font-size: 16px;
	line-height: 28px;
	font-weight: 700;
	text-transform: none;
	opacity: 0;
	transform: translateY(80px);
	transition: all 0.4s ease-in-out 0.1s;
	font-family: 'Open Sans', sans-serif;
}
.main-slider .content h5 span {
	position: relative;
	display: inline-block;
	padding: 0 20px;
}
.main-slider .active .content h5 {
	opacity: 1;
	transform: translateY(0px);
	transition: all 0.4s ease-in-out 0.3s;
}
.main-slider .content h2 {
	position: relative;
	color: #fff;
	font-size: 47px;
	line-height: 1.2em;
	font-weight: 700;
	margin-top: 15px;
	margin-bottom: 42px;
	opacity: 0;
	transform: translateY(80px);
	transition: all 0.4s ease-in-out 0.1s;
}
.main-slider .content h2 .round {
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	border-radius: 50%;
}
.main-slider .active .content h2 {
	opacity: 1;
	transform: translateY(0px);
	transition: all 0.7s ease-in-out 0.5s;
}
.main-slider .content .btns-box {
	position: relative;
	opacity: 0;
	transform: translateY(80px);
	transition: all 0.4s ease-in-out 0.1s;
}
.main-slider .content .btns-box a {
	line-height: 60px;
}
.main-slider .content .btns-box a.marleft {
	margin-left: 16px;
}
.main-slider .content .btns-box a {
}
.main-slider .active .content .btns-box {
	opacity: 1;
	transform: translateY(0px);
	transition: all 0.7s ease-in-out 0.7s;
}

.main-slider .owl-theme .owl-nav {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;
	transition-delay: 0.1s;
	transition-timing-function: ease-in-out;
	transition-duration: 0.5s;
	transition-property: all;
	transform-origin: bottom;
	transform-style: preserve-3d;
	opacity: 0;
	transform: scale(1);
}
.main-slider:hover .owl-theme .owl-nav {
	opacity: 1;
	transform: scale(1);
}
.main-slider .owl-theme .owl-prev span,
.main-slider .owl-theme .owl-next span {
	display: block;
}
.main-slider .owl-theme .owl-nav .owl-prev {
	position: absolute;
	left: 50px;
	top: 0;
	transform: rotate(180deg);
	background: #ffffff;
	border: 2px solid #ffffff;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	text-align: center;
	color: #353742;
	font-size: 25px;
	line-height: 56px;
	font-weight: 400;
	opacity: 1;
	margin: 0;
	padding: 0;
	margin-top: -30px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.main-slider .owl-theme .owl-nav .owl-next {
	position: absolute;
	right: 50px;
	top: 0;
	background: #ffffff;
	border: 2px solid #ffffff;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	text-align: center;
	color: #353742;
	font-size: 25px;
	line-height: 56px;
	font-weight: 400;
	opacity: 1;
	margin: 0;
	padding: 0;
	margin-top: -30px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.banner-carousel .owl-dots {
	position: absolute;
	left: 0;
	bottom: 70px;
	right: 0;
	display: block;
	text-align: center;
	max-width: 1170px;
	width: 100%;
	margin: 0 auto !important;
	line-height: 0;
	display: none;
}
.banner-carousel .owl-dots .owl-dot {
	position: relative;
	display: inline;
	height: 20px;
	width: 20px;
	background: transparent;
	border: 1px solid #ffffff;
	border-radius: 50%;
	margin: 0 10px;
	transition: all 500ms linear;
	transition-delay: 0.1s;
}
.banner-carousel .owl-dots .owl-dot span {
	position: relative;
	display: inline-block;
	width: 10px;
	height: 10px;
	background: #ffffff;
	border-radius: 50%;
}
.banner-carousel .owl-dots .owl-dot.active {
	background-color: #0098ff;
	border-color: #0098ff;
}

/*** 
=============================================
    Featured Area Css
=============================================
***/
.featured-area {
	position: relative;
	display: block;
	margin-top: -70px;
	padding-bottom: 120px;
	z-index: 10;
}
.main_page.active_boxlayout .featured-area {
	background: #ffffff;
}
.featured-area.one-page-featured {
	padding-bottom: 0;
}

.single-featured-box {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 27px 40px 26px;
	-webkit-box-shadow: 0px 4px 9px 2px rgba(20, 33, 43, 0.05);
	box-shadow: 0px 4px 9px 2px rgba(20, 33, 43, 0.05);
	border-radius: 0.5rem !important;
}
.single-featured-box .inner {
	position: relative;
	display: block;
	padding-left: 60px;
}
.single-featured-box .inner .icon {
	position: absolute;
	top: 3px;
	left: 0;
}
.single-featured-box .inner .icon span:before {
	font-size: 60px;
	line-height: 60px;
}

.single-featured-box .inner .text {
	position: relative;
	display: block;
	padding-left: 20px;
}
.single-featured-box .inner .text h3 {
	font-size: 24px;
	line-height: 34px;
	margin: 0 0 5px;
}
.single-featured-box .inner .text p {
	font-size: 14px;
	line-height: 24px;
	margin: 0;
}

/*** 
=============================================
    About Style1 Area Css
=============================================
***/
.about-style1-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding-bottom: 120px;
}
.about-style1-area.one-page-about {
	padding-top: 120px;
}

.about-style1-image-box {
	position: relative;
	display: block;
	max-width: 575px;
	width: 100%;
	z-index: 10;
}
.about-style1-image-box-bg {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-repeat: no-repeat;
	background-position: left bottom;
	z-index: -1;
}
.about-style1-image-box .main-image {
	position: relative;
	display: block;
	padding-left: 30px;
}

.about-experience-box {
	position: absolute;
	top: 0;
	right: 40px;
	width: 185px;
	height: 185px;
	border-radius: 30px;
	text-align: center;
}
.about-experience-box .count-box {
	position: relative;
	display: block;
	padding: 47px 0;
}
.about-experience-box .count-box h2 {
	color: #ffffff;
	font-size: 70px;
	line-height: 60px;
	font-weight: 700;
	font-family: 'Open Sans', sans-serif;
}
.about-experience-box .count-box h2 .plus-icon {
	position: relative;
	display: inline-block;
	top: -1px;
	font-size: 20px;
	margin-left: -15px;
}
.about-experience-box .count-box h5 {
	color: #ffffff;
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	margin: 11px 0 0;
}

.about-style1-content-box {
	position: relative;
	display: block;
	margin-left: -30px;
}
.about-style1-content-box .sec-title {
	padding-bottom: 32px;
}
.about-style1-content-box .inner-content {
	position: relative;
	display: block;
}
.about-style1-content-box .text {
	margin-bottom: 33px;
}
.about-style1-content-box ul {
	position: relative;
	display: block;
	overflow: hidden;
}
.about-style1-content-box ul li {
	position: relative;
	display: block;
	margin-bottom: 20px;
}
.about-style1-content-box ul li .icon {
	width: 40px;
}
.about-style1-content-box ul li .icon span:before {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	color: #ffffff;
	font-size: 14px;
	line-height: 40px;
	text-align: center;
}
.about-style1-content-box ul li .icon,
.about-style1-content-box ul li .title {
	display: table-cell;
	vertical-align: middle;
}
.about-style1-content-box ul li .title {
	padding-left: 20px;
}
.about-style1-content-box ul li .title h5 {
	font-size: 16px;
	line-height: 26px;
}

/*** 
=============================================
    Service style1 Area Css
=============================================
***/
.service-style1-area {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding: 120px 0 90px;
}

.service-style1-area.service-page {
	background: transparent;
	padding: 0px 0 90px;
	margin-top: -89px;
	z-index: 10;
}

.service-style1-area .shape1 {
	position: absolute;
	top: 0;
	left: 0;
}
.service-style1-area .shape2 {
	position: absolute;
	top: 150px;
	right: 250px;
	-webkit-animation: service_hexagon_2 15s infinite linear;
	-moz-animation: service_hexagon_2 15s infinite linear;
	-o-animation: service_hexagon_2 15s infinite linear;
	animation: service_hexagon_2 15s infinite linear;
}

.single-service-style1 {
	position: relative;
	display: block;
	background: #ffffff;
	margin-bottom: 30px;
	border-radius: 0.5rem !important;
}
.single-service-style1 .img-holder {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 10px;
	padding-bottom: 0;
}
.single-service-style1 .img-holder .inner {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-service-style1 .img-holder img {
	width: 100%;
	transform: scale(1.01);
	transition: all 700ms ease;
	border-radius: 0.5rem !important;
}
.single-service-style1:hover .img-holder img {
	transform: scale(1.2);
}
.single-service-style1 .img-holder::before {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	content: '';
	width: 0;
	height: 0;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
	z-index: 10;
}
.single-service-style1:hover .img-holder::before {
	-webkit-animation: circle 0.75s;
	animation: circle 0.75s;
}

.single-service-style1 .text-holder {
	position: relative;
	display: block;
	padding: 24px 30px 26px;
	padding-right: 20px;
	border-top: none;
	-webkit-box-shadow: 0px 4px 11px 2px rgba(0, 0, 0, 0.04);
	box-shadow: 0px 4px 11px 2px rgba(0, 0, 0, 0.04);
}
.single-service-style1 .text-holder h3 {
	font-size: 22px;
	line-height: 34px;
	font-weight: 700;
	margin: 0 0 5px;
}
.single-service-style1 .text-holder h3 a {
	color: #393d72;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.single-service-style1 .text-holder p {
	color: #393d72;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	margin: 0;
}
.single-service-style1 .text-holder .button {
	position: relative;
	display: block;
	padding-top: 10px;
}
.single-service-style1 .text-holder .button a {
	color: #c9cbe5;
	font-size: 14px;
	line-height: 24px;
	font-weight: 600;
	text-decoration: underline;
	font-family: 'Poppins', sans-serif;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

/*** 
=============================================
   Video Gallery Area Css
=============================================
***/
.video-gallery-area {
	position: relative;
	display: block;
}
.video-gallery-area .row {
	margin-left: 0px;
	margin-right: 0px;
}
.video-gallery-area .row [class*='col-'] {
	padding-left: 0px;
	padding-right: 0px;
}

.video-gallery-content-box {
	position: relative;
	max-width: 620px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	padding: 0 30px;
	z-index: 1;
}
.video-gallery-content-box h2 {
	color: #ffffff;
	font-size: 40px;
	line-height: 70px;
	font-weight: 700;
	margin: 26px 0 20px;
}
.video-gallery-content-box p {
	color: #ffffff;
	margin: 0;
}
.video-gallery-content-box .button {
	position: relative;
	display: block;
	padding-top: 43px;
}
.video-gallery-content-box .button a {
	background: #222444;
	line-height: 60px;
}
.video-gallery-content-box .button a i {
	position: relative;
	display: inline-block;
	float: left;
	font-size: 16px;
	line-height: 60px;
	padding-right: 10px;
}

.video-holder-box {
	position: relative;
	display: block;
	background-attachment: scroll;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 300px 0;
	z-index: 1;
}
.video-holder-box:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: '';
	background: rgba(34, 36, 68, 0.4);
	z-index: -1;
}
.video-holder-box .icon {
	position: relative;
	display: block;
	width: 120px;
	height: 120px;
	background: #ffffff;
	border: 0px solid rgba(54, 124, 232, 0.1);
	border-radius: 50%;
	margin: 0 auto;
	padding: 0px;
	z-index: 1;
}
.video-holder-box .icon:before {
	position: absolute;
	content: '';
	top: -10px;
	left: -10px;
	bottom: -10px;
	right: -10px;
	border-radius: 50%;
	background: rgba(54, 124, 232, 0);
	z-index: -1;
}
.video-holder-box:hover .icon:before {
	-webkit-box-shadow: 0 0 0 0 rgba(54, 124, 232, 0.09);
	box-shadow: 0 0 0 0 rgba(54, 124, 232, 0.09);
	-webkit-animation: ripple 1s infinite;
	animation: ripple 3s infinite;
}
.video-holder-box .icon a span:before {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	color: #222444;
	font-size: 25px;
	line-height: 120px;
}

.video-holder-box.style2 {
	padding: 215px 0;
	max-width: 620px;
	width: 100%;
	float: right;
}
.video-holder-box.style2:before {
	display: none;
}

.video-holder-box.style3 {
	padding: 170px 0;
	max-width: 100%;
	width: 100%;
	float: none;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
}
.video-holder-box.style3:before {
	display: none;
}

/*** 
=============================================
    Feautres Area Css
=============================================
***/
.feautres-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 120px 0 120px;
}

.working-hours-box {
	position: relative;
	display: block;
	padding: 40px;
	background-attachment: scroll;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.working-hours-box .inner-content {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 44px 50px 41px;
}
.working-hours-box .title {
	position: relative;
	display: block;
	padding-bottom: 25px;
}
.working-hours-box .title h3 {
	color: #393d72;
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	margin: 0 0 6px;
}
.working-hours-box .title h3 span {
	position: relative;
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
}
.working-hours-box .title p {
	margin: 0;
}
.working-hours-box ul {
	position: relative;
	display: block;
	overflow: hidden;
}
.working-hours-box ul li {
	position: relative;
	display: block;
	line-height: 30px;
	margin-bottom: 5px;
	z-index: 1;
}
.working-hours-box ul li:before {
	position: absolute;
	top: 15px;
	left: 0;
	right: 0;
	height: 1px;
	border-bottom: 1px dashed #e6e7f2;
	content: '';
}
.working-hours-box ul li:last-child {
	margin-bottom: 0;
}
.working-hours-box ul li .left {
	position: relative;
	display: inline-block;
	background: #ffffff;
	padding-right: 40px;
	color: #393d72;
	font-size: 16px;
	font-weight: 700;
}
.working-hours-box ul li .right {
	position: relative;
	display: inline-block;
	float: right;
	background: #ffffff;
	padding-left: 40px;
	font-size: 16px;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
}

.feautres-content-box {
	position: relative;
	display: block;
	padding-top: 50px;
	max-width: 550px;
	width: 100%;
	float: right;
}
.feautres-content-box .sec-title {
	padding-bottom: 24px;
}
.feautres-content-box .sec-title h2 {
	font-size: 52px;
	margin: 11px 0 0;
}
.feautres-content-box .inner-content {
	position: relative;
	display: block;
}
.feautres-content-box .inner-content .text {
	margin-bottom: 30px;
}

.feautres-content-box .inner-content ul {
	position: relative;
	display: block;
	overflow: hidden;
	width: 100%;
}
.feautres-content-box .inner-content ul:before {
	position: absolute;
	left: 50%;
	bottom: 0;
	width: 2px;
	height: 60px;
	content: '';
	background: #f2f3fa;
}
.feautres-content-box .inner-content ul.top {
	border-bottom: 2px solid #f2f3fa;
	padding-bottom: 23px;
}

.feautres-content-box .inner-content ul.bottom {
	padding-top: 23px;
}

.feautres-content-box .inner-content ul li {
	position: relative;
	display: inline-block;
	float: left;
	width: 50%;
}
.feautres-content-box .inner-content ul li:last-child {
	padding-left: 30px;
}

.feautres-content-box .inner-content ul li .inner {
	position: relative;
	display: block;
	padding-left: 60px;
}
.feautres-content-box .inner-content ul li .icon {
	position: absolute;
	top: 8px;
	left: 0;
}
.feautres-content-box .inner-content ul li .icon span:before {
	font-size: 40px;
	line-height: 40px;
}
.feautres-content-box .inner-content ul li .title {
	position: relative;
	display: block;
}
.feautres-content-box .inner-content ul li .title h3 {
	font-size: 20px;
	margin: 0 0 1px;
}
.feautres-content-box .inner-content ul li .title p {
	margin: 0;
}

.feautres-content-box .inner-content ul.bottom:before {
	position: absolute;
	left: 50%;
	top: 0;
	bottom: auto;
	width: 2px;
	height: 60px;
	content: '';
	background: #f2f3fa;
}

/*** 
=============================================
   Team Area Css
=============================================
***/
.team-area {
	position: relative;
	display: block;
	padding: 70px 0 80px;
	background: #f4f2ef;
}

.single-team-member {
	position: relative;
	display: block;
	margin-bottom: 40px;
}
.single-team-member .img-holder {
	position: relative;
	display: block;
	width: 220px;
	height: 220px;
	padding: 10px;
	background: #ffffff;
	margin: 0 auto;
	border-radius: 50%;
}
.single-team-member .img-holder .round-top {
	position: absolute;
	top: -13px;
	left: 80px;
	width: 45px;
	height: 45px;
	background: #ffffff;
	border-radius: 50%;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.single-team-member:hover .img-holder .round-top {
	background: #f9be4f;
}
.single-team-member .img-holder .round-bottom {
	position: absolute;
	bottom: 0px;
	right: 20px;
	width: 70px;
	height: 70px;
	background: #ffffff;
	border-radius: 50%;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}

.single-team-member .img-holder .inner {
	position: relative;
	display: block;
}
.single-team-member .overlay-style-one {
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border-radius: 50%;
	transform: scale(1.3);
}
.single-team-member:hover .overlay-style-one {
	opacity: 1;
	transform: scale(1);
	border-radius: 50%;
}
.single-team-member .img-holder .inner img {
	width: 100%;
	transform: scale(1);
	transition: 0.3s ease-in-out;
	border-radius: 50%;
}
.single-team-member:hover .img-holder .inner img {
}

.single-team-member .title-holder {
	position: relative;
	display: block;
	padding: 31px 0 0;
}
.single-team-member .title-holder h5 {
	font-size: 16px;
	line-height: 30px;
	font-weight: 700;
	text-transform: capitalize;
}
.single-team-member .title-holder h3 {
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
	margin: 0 0 11px;
}
.single-team-member .title-holder h3 a {
	color: #393d72;
	transition: all 500ms linear;
	transition-delay: 0.1s;
}

.team-social-link {
	position: relative;
	display: block;
}
.team-social-link ul {
	overflow: hidden;
}
.team-social-link ul li {
	position: relative;
	display: inline-block;
	margin-right: 6px;
}
.team-social-link ul li:last-child {
	margin-right: 0;
}
.team-social-link ul li a {
	position: relative;
	display: block;
	background: #3b5999;
	height: 40px;
	width: 40px;
	color: #ffffff;
	font-size: 14px;
	line-height: 40px;
	border-radius: 7px;
}
.team-social-link ul li a.tw {
	background: #55acee;
}
.team-social-link ul li a.linkedin {
	background: #0077b5;
}

/*** 
=============================================
    Priceing Plan Area Css
=============================================
***/
.priceing-plan-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 120px 0 90px;
}
.priceing-plan-content {
	position: relative;
	display: block;
}

.priceing-plan-tabs {
	position: relative;
	display: block;
}
.priceing-plan-tabs .tab-button-column {
	position: absolute;
	top: -140px;
	right: 0;
}
.priceing-plan-tabs .tab-button-column .tab-buttons {
	position: relative;
	display: block;
	overflow: hidden;
	z-index: 1;
}
.priceing-plan-tabs .tab-button-column .tab-buttons li {
	position: relative;
	display: inline-block;
	float: left;
	background: #f4f2ef;
	width: 130px;
	color: #393d72;
	font-size: 16px;
	line-height: 60px;
	font-weight: 700;
	text-align: center;
	cursor: pointer;
	font-family: 'Poppins', sans-serif;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.priceing-plan-tabs .tab-button-column .tab-buttons li:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.priceing-plan-tabs .tab-button-column .tab-buttons li:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}
.priceing-plan-tabs .tab-button-column .tab-buttons li.tab-btn:hover,
.priceing-plan-tabs .tab-button-column .tab-buttons li.tab-btn.active-btn {
	color: #ffffff;
	background: #f9be4f;
}

.priceing-plan-tabs .tabs-content .tab {
	position: relative;
	display: none;
	-webkit-transform: translateY(35px);
	-ms-transform: translateY(35px);
	transform: translateY(35px);
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
	z-index: 10;
}
.priceing-plan-tabs .tabs-content .tab.active-tab {
	display: block;
	margin-top: 0px;
	-webkit-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
}

.priceing-plan-tab-content {
	position: relative;
	display: block;
}
.single-priceing-plan-box {
	position: relative;
	display: block;
	padding: 57px 40px 60px;
	background: #f4f2ef;
	border-radius: 20px;
	max-width: 400px;
	width: 100%;
	margin: 0 auto 30px;
}

.single-priceing-plan-box .top {
	overflow: hidden;
}
.single-priceing-plan-box .top .left {
	position: relative;
	display: block;
}
.single-priceing-plan-box .top .left p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
	margin: 0 0 2px;
}
.single-priceing-plan-box .top .left h2 {
	font-size: 36px;
	line-height: 46px;
	font-weight: 700;
}
.single-priceing-plan-box .top .right {
	position: relative;
	display: block;
	top: 10px;
}
.single-priceing-plan-box .top .right h2 {
	color: #393d72;
	font-size: 50px;
	line-height: 50px;
	font-weight: 700;
}
.single-priceing-plan-box .top .right h2 span {
	position: relative;
	display: inline-block;
	padding-right: 1px;
	top: -2px;
	font-size: 16px;
	line-height: 0px;
}

.single-priceing-plan-box ul {
	position: relative;
	display: block;
	overflow: hidden;
	padding-top: 26px;
	padding-bottom: 32px;
}
.single-priceing-plan-box ul li {
	position: relative;
	display: block;
	color: #393d72;
	font-size: 18px;
	line-height: 40px;
	font-weight: 700;
}
.single-priceing-plan-box ul li.deactive {
	color: #bcbbb9;
}

.single-priceing-plan-box ul li span {
	position: relative;
	display: block;
	float: right;
}
.single-priceing-plan-box ul li span.deactive:before {
	color: #bcbbb9;
}

.single-priceing-plan-box .button {
	position: relative;
	display: block;
}
.single-priceing-plan-box .button a {
	color: #393d72;
	padding: 0 40px;
	line-height: 60px;
	background: #ffffff;
}
.single-priceing-plan-box .button a:hover {
	color: #ffffff;
	background: #20234c;
}

.single-priceing-plan-box .button a i {
	position: relative;
	display: inline-block;
	float: left;
	font-size: 16px;
	line-height: 60px;
	padding-right: 10px;
}
.single-priceing-plan-box .button a:hover:after {
	display: none;
}

.single-priceing-plan-box.style2 {
	background: #393d72;
}
.single-priceing-plan-box.style2 .top .left h2 {
	color: #ffffff;
}
.single-priceing-plan-box.style2 .top .right h2 {
	color: #ffffff;
}
.single-priceing-plan-box.style2 ul li {
	color: #ffffff;
}
.single-priceing-plan-box.style2 ul li.deactive {
	color: #5e6196;
}
.single-priceing-plan-box.style2 ul li span.deactive:before {
	color: #5e6196;
}
.single-priceing-plan-box.style2 .button a {
	color: #ffffff;
}
.single-priceing-plan-box.style2 .button a i {
	color: #ffffff;
}
.single-priceing-plan-box.style2 .button a:hover {
	background: #181a3d;
}

.single-priceing-plan-box.style3 .top .left p {
	color: #ffffff;
}
.single-priceing-plan-box.style3 .top .left h2 {
	color: #ffffff;
}
.single-priceing-plan-box.style3 .top .right h2 {
	color: #ffffff;
}
.single-priceing-plan-box.style3 .top .right h2 span {
	color: #ffffff;
}
.single-priceing-plan-box.style3 ul li {
	color: #ffffff;
}
.single-priceing-plan-box.style3 .button a {
	color: #ffffff;
	background: #393d72;
}
.single-priceing-plan-box.style3 .button a i {
	color: #ffffff;
}
.single-priceing-plan-box.style3 ul li span:before {
	color: #fff3f7;
}
.single-priceing-plan-box.style3 .button a:hover {
	background: #181a3d;
}

/*** 
=============================================
    Testimonial Style1 Area Css
=============================================
***/
.testimonial-style1-area {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding: 120px 0 120px;
	z-index: 1;
}
.testimonial-style1-area .image-box1 {
	position: absolute;
	top: 50px;
	left: 10%;
	-webkit-animation: animation1 5s ease-in infinite;
	animation: animation1 5s ease-in infinite;
}
.testimonial-style1-area .image-box2 {
	position: absolute;
	top: 120px;
	right: 10%;
	-webkit-animation: animation1 5s ease-in infinite;
	animation: animation1 5s ease-in infinite;
}
.testimonial-style1-area .image-box3 {
	position: absolute;
	left: 6%;
	bottom: 200px;
}
.testimonial-style1-area .image-box4 {
	position: absolute;
	right: 13%;
	bottom: 130px;
}
.testimonial-style1-area .layer-outer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-attachment: scroll;
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	z-index: -1;
}

.testimonial-carousel {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 40px;
	border-radius: 35px;
	-webkit-box-shadow: 0px 5px 7px 2px rgba(20, 33, 43, 0.04);
	box-shadow: 0px 5px 7px 2px rgba(20, 33, 43, 0.04);
	z-index: 10;
}
.testimonial-carousel:before {
	position: absolute;
	top: 30px;
	left: 30px;
	bottom: -30px;
	right: 30px;
	background-color: rgba(255, 255, 255, 0.6);
	content: '';
	border-radius: 35px;
	z-index: -1;
}
.single-testimonial-style1 {
	position: relative;
	display: block;
}
.single-testimonial-style1 .img-holder {
	width: 320px;
}
.single-testimonial-style1 .img-holder img {
	width: 100%;
	border-radius: 35px;
}

.single-testimonial-style1 .img-holder,
.single-testimonial-style1 .text-holder {
	display: table-cell;
	vertical-align: middle;
}
.single-testimonial-style1 .text-holder {
	position: relative;
	padding-left: 50px;
}
.single-testimonial-style1 .text-holder h2 {
	color: #393d72;
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
	margin: 0 0 4px;
}
.single-testimonial-style1 .text-holder span {
	font-size: 16px;
	font-weight: 700;
}
.single-testimonial-style1 .text-holder .text-box {
	position: relative;
	display: block;
	padding-top: 29px;
}
.single-testimonial-style1 .text-holder .text-box p {
	color: #393d72;
	font-size: 26px;
	line-height: 40px;
	font-weight: 400;
	margin-bottom: 0;
}

/*** 
=============================================
    Blog Style1 Area Css
=============================================
***/
.blog-style1-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 120px 0 100px;
}

.single-blog-style1 {
	position: relative;
	display: block;
}
.single-blog-style1 .img-holder {
	position: relative;
	display: block;
}
.single-blog-style1 .img-holder .date-box {
	position: absolute;
	top: 20px;
	left: 20px;
	background: #ffffff;
	padding: 3px 20px 3px;
	z-index: 5;
}
.single-blog-style1 .img-holder .date-box h5 {
	color: #393d72;
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
}

.single-blog-style1 .img-holder .inner {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-blog-style1 .img-holder .inner:before {
	position: absolute;
	content: '';
	top: 0px;
	left: 0px;
	width: 100%;
	height: 0%;
	opacity: 0;
	transform-style: preserve-3d;
	transform: translateY(100px, 50px);
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	z-index: 1;
}
.single-blog-style1:hover .img-holder .inner:before {
	opacity: 1;
	height: 100%;
}
.single-blog-style1 .img-holder .inner img {
	width: 100%;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.4s ease;
	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
	border-radius: 0.5rem !important;
}
.single-blog-style1:hover .img-holder .inner img {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
	border-radius: 0.5rem !important;
}

.single-blog-style1 .text-holder {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 35px 30px 36px;
	padding-right: 20px;
	-webkit-box-shadow: 0px 3px 10px 2px rgba(20, 33, 43, 0.04);
	box-shadow: 0px 3px 10px 2px rgba(20, 33, 43, 0.04);
}
.single-blog-style1 .text-holder .meta-info {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-blog-style1 .text-holder .meta-info li {
	position: relative;
	display: inline-block;
	float: left;
	line-height: 24px;
	padding-right: 17px;
	margin-right: 15px;
}
.single-blog-style1 .text-holder .meta-info li:before {
	position: absolute;
	top: 4px;
	right: 0;
	bottom: 4px;
	width: 2px;
	background: #e7e7e7;
	content: '';
}
.single-blog-style1 .text-holder .meta-info li:last-child:before {
	display: none;
}
.single-blog-style1 .text-holder .meta-info li:last-child {
	padding-right: 0px;
	margin-right: 0px;
}
.single-blog-style1 .text-holder .meta-info li span:before {
	position: relative;
	display: inline-block;
	padding-right: 6px;
	font-size: 14px;
	top: 0px;
}
.single-blog-style1 .text-holder .meta-info li a {
	color: #393d72;
	font-size: 14px;
	font-weight: 700;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.single-blog-style1 .text-holder .blog-title {
	font-size: 20px;
	line-height: 32px;
	font-weight: 700;
	margin: 9px 0 0px;
}
.single-blog-style1 .text-holder .blog-title a {
	color: #18215e;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.single-blog-style1 .text-holder .blog-title .round-box {
	position: relative;
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
}
.blog-carousel.owl-nav-style-one.owl-theme .owl-stage-outer {
	padding-bottom: 20px;
}

/*** 
=============================================
    Footer area style
=============================================
***/
.footer-area {
	position: relative;
	display: block;
	padding-top: 120px;
	z-index: 1;
}
.footer-area .footer-bg {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
}

.footer {
	position: relative;
	display: block;
	padding: 0 0px 110px;
}
.footer-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
}
.footer-logo .logo {
	position: relative;
}
.footer-logo .logo a {
	display: inline-block;
}
.footer-logo .copy-right {
	border-left: 2px solid #322b6d;
	padding-left: 20px;
	margin-left: 20px;
}
.footer-logo .copy-right p {
	color: #8689b3;
	font-size: 14px;
	line-height: 23px;
	margin: 0;
}
.footer-logo .copy-right h4 {
	color: #ffffff;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	margin: 3px 0 0;
}

.footer-menu {
	position: relative;
	display: block;
	text-align: center;
	border-top: 2px solid #343763;
	border-bottom: 2px solid #343763;
	padding: 35px 0;
}
.footer-menu ul {
}
.footer-menu ul li {
	display: inline-block;
}
.footer-menu ul li + li {
	margin-left: 75px;
}
.footer-menu ul li a {
	color: #ffffff;
	font-size: 16px;
	font-weight: 700;
	transition: all 500ms ease;
}

.footer-contact-info {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 41px;
}
.footer-contact-info .single-box {
	display: flex;
	flex-direction: row;
}
.footer-contact-info .single-box + .single-box {
	margin-left: 50px;
}
.footer-contact-info .single-box .icon {
	position: relative;
	top: 8px;
	display: block;
	height: 30px;
	width: 30px;
	color: #ffffff;
	border-radius: 5px;
	text-align: center;
	line-height: 25px;
}
.footer-contact-info .single-box .icon span {
	position: relative;
	display: block;
	color: #ffffff;
	font-size: 16px;
	line-height: 30px;
}

.footer-contact-info .single-box .title {
	margin-left: 15px;
}
.footer-contact-info .single-box .title span {
	color: #8285ab;
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
}
.footer-contact-info .single-box .title h3 {
	color: #ffffff;
	font-size: 20px;
	font-weight: 700;
	line-height: 35px;
	margin-top: 4px;
}
.footer-contact-info .single-box .title h3 a {
	color: #ffffff;
}

.footer-social-info {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 100px;
}
.footer-social-info a {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 50px;
	background: #3b5999;
	color: #ffffff;
	border-radius: 10px;
}
.footer-social-info a.fb-clr {
	background: #3b5999;
}
.footer-social-info a.tw-clr {
	background: #55acee;
}
.footer-social-info a.you-clr {
	background: #cd201f;
}
.footer-social-info a.sk-clr {
	background: #00aff0;
}
.footer-social-info a + a {
	margin-left: 10px;
}

/*** 
=====================================================
	Main Slider style
=====================================================
***/
.main-slider.style2 {
	position: relative;
	display: block;
	padding: 260px 0px 215px;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
}
.main-slider.style2 .banner-curve {
	position: absolute;
	left: 0;
	bottom: -2px;
	width: 100%;
	height: 90px;
	background-image: url(../images/shape/banner-curve.png);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 100% 100%;
	z-index: 2;
}

.main-slider.style2 .content {
	position: relative;
	z-index: 11;
}
.main-slider.style2 .content {
	position: relative;
	display: block;
	width: 100%;
}

.video-holder-box-style2 {
	position: relative;
	display: block;
}
.video-holder-box-style2 .icon {
	position: relative;
	display: block;
	width: 120px;
	height: 120px;
	background: transparent;
	border: 2px solid rgba(255, 255, 255, 0.2);
	border-radius: 50%;
	margin: 0 auto;
	padding: 13px;
	z-index: 1;
}
.video-holder-box-style2 .icon:before {
	position: absolute;
	content: '';
	top: -10px;
	left: -10px;
	bottom: -10px;
	right: -10px;
	border-radius: 50%;
	background: rgba(54, 124, 232, 0);
	z-index: -1;
}
.main-slider.style2:hover .video-holder-box-style2 .icon:before {
	-webkit-box-shadow: 0 0 0 0 rgba(54, 124, 232, 0.09);
	box-shadow: 0 0 0 0 rgba(54, 124, 232, 0.09);
	-webkit-animation: ripple 1s infinite;
	animation: ripple 3s infinite;
}
.video-holder-box-style2 .icon a span:before {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	color: #ffffff;
	font-size: 25px;
	line-height: 90px;
}

.main-slider.style2 .content h5 {
	color: #ffffff;
	font-size: 16px;
	line-height: 28px;
	font-weight: 700;
	opacity: 1;
	margin: 33px 0 0;
	text-transform: none;
	transform: translateY(0px);
	transition: all 0.4s ease-in-out 0.1s;
	font-family: 'Open Sans', sans-serif;
}
.main-slider.style2 .content h5 span {
	position: relative;
	display: inline-block;
	padding: 0 20px;
}
.main-slider.style2 .content h2 {
	position: relative;
	color: #ffffff;
	font-size: 72px;
	line-height: 1.2em;
	font-weight: 700;
	opacity: 1;
	margin-top: 20px;
	transform: translateY(0px);
	transition: all 0.4s ease-in-out 0.1s;
}
.main-slider.style2 .content h2 .round {
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	border-radius: 50%;
}

/*** 
=============================================
    Thm Formbox Style1 Area
=============================================
***/
.thm-formbox-style1-area {
	position: relative;
	display: block;
	margin-top: -100px;
}
.thm-formbox-style1-area.style2 {
	margin-top: -60px;
}

.thm-formbox-style1 {
	position: relative;
	display: block;
	border-radius: 20px;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 8px 16px 0px rgba(20, 33, 43, 0.04);
	padding: 30px;
	padding-bottom: 20px;
	z-index: 100;
}
.thm-formbox1 {
	position: relative;
	display: block;
}
.thm-formbox1 .input-box {
	position: relative;
	display: block;
	margin-bottom: 10px;
}
.thm-formbox1 input[type='text'],
.thm-formbox1 input[type='email'] {
	position: relative;
	display: block;
	border-radius: 14px;
	background-color: rgb(244, 242, 239);
	border: 0px solid #e5e5e5;
	width: 100%;
	height: 60px;
	color: #393d72;
	font-size: 14px;
	font-weight: 700;
	font-style: normal;
	padding-left: 55px;
	padding-right: 20px;
	transition: all 500ms ease;
	font-family: 'Open Sans', sans-serif;
}
.thm-formbox1 .input-box .icon {
	position: absolute;
	top: 50%;
	left: 30px;
	transform: translateY(-50%);
	font-size: 16px;
	z-index: 10;
}
.thm-formbox1 input[type='text']::-webkit-input-placeholder {
	color: #393d72;
}
.thm-formbox1 input[type='text']:-moz-placeholder {
	color: #393d72;
}
.thm-formbox1 input[type='text']::-moz-placeholder {
	color: #393d72;
}
.thm-formbox1 input[type='text']:-ms-input-placeholder {
	color: #393d72;
}

.thm-formbox1 .bootstrap-select {
	position: relative;
	display: block;
	width: 100% !important;
	height: 60px;
}
.thm-formbox1 .bootstrap-select > .dropdown-toggle {
	position: relative;
	display: block;
	outline: none !important;
	border-radius: 14px;
	border: 0px solid #cccccc;
	background-color: #f4f2ef !important;
	max-width: 100%;
	width: 100%;
	height: 60px;
	margin: 0;
	padding: 0;
	padding-left: 55px;
	padding-right: 20px;
	color: #393d73 !important;
	font-size: 14px;
	line-height: 60px;
	font-weight: 700;
	letter-spacing: normal;
	text-transform: capitalize;
	box-shadow: none !important;
	font-family: 'Open Sans', sans-serif;
}
.thm-formbox1 .bootstrap-select .dropdown-toggle:focus {
	outline: none !important;
}
.thm-formbox1 .bootstrap-select .dropdown-menu li a span.text {
	display: inline-block;
	margin: 0;
}
.thm-formbox1 .bootstrap-select > .dropdown-toggle::after {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 28px;
	width: auto;
	height: auto;
	font-family: 'icomoon' !important;
	content: '\e93c';
	color: #c9c5bf;
	font-size: 14px;
	margin: 0;
	border: none;
}

.thm-formbox1 .bootstrap-select .dropdown-menu {
	margin: 0;
	padding: 0;
	border-radius: 14px;
	border: 0px solid #ddd;
	background: #eae8e4;
	font-size: 16px;
	color: #000000;
}
.thm-formbox1 .bootstrap-select .dropdown-menu li {
	position: relative;
	border-bottom: 1px solid #d7d7d7;
}
.thm-formbox1 .dropdown-item {
	display: block;
	width: 100%;
	padding: 9px 20px 9px;
	color: #222222;
	font-size: 14px;
	font-weight: 700;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	transition: all 500ms ease;
	font-family: 'Open Sans', sans-serif;
}

.thm-formbox1 .button-box {
	position: relative;
	display: block;
}
.thm-formbox1 .button-box button {
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 0;
	line-height: 60px;
}

/*** 
=============================================
    About Style2 Area
=============================================
***/
.about-style2-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 120px 0 80px;
}
.about-style2-area2 {
	padding: 80px 0 80px !important;
}
.about-style1-content-box.style1instyle3 {
	padding-top: 0px !important;
}
.about-style1-content-box.style1instyle2 {
	padding-top: 100px;
	margin: 0;
	margin-right: -40px;
	z-index: 10;
}
.about-style1-content-box.style1instyle2 .sec-title h2 {
	font-size: 37px;
	margin: 11px 0 0;
	text-transform: none !important;
}

.about-style1-content-box.style1instyle2 .inner-content .button-box {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	margin-right: -50px;
	padding-top: 20px;
}
.about-style1-content-box.style1instyle2 .inner-content .button-box a {
	background: #393d72;
	line-height: 60px;
	padding-left: 30px;
	padding-right: 30px;
	float: left;
}
.about-style1-content-box.style1instyle2 .inner-content .button-box a.marleft {
	margin-left: 20px;
}
.about-style1-content-box.style1instyle2 .inner-content .button-box a.style2 {
	color: #ffffff;
}
.about-style1-content-box.style1instyle2 .inner-content .button-box a i {
	position: relative;
	display: inline-block;
	float: left;
	color: #ffffff;
	font-size: 16px;
	line-height: 60px;
	padding-right: 10px;
	z-index: 1;
}

.about-style2-image-box {
	position: relative;
	display: block;
	margin-left: 14px;
	margin-right: -30px;
}
.about-style2-image-box img {
	width: 100%;
}

.cost-calculator-box {
	position: relative;
	display: block;
	padding: 45px 50px 40px;
	border-radius: 30px;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 6px 12px 0px rgba(20, 33, 43, 0.06);
	margin: 50px 0;
	margin-left: -60px;
	z-index: 10;
}
.cost-calculator-box .title {
	position: relative;
	display: block;
	padding-bottom: 23px;
}
.cost-calculator-box .title h3 {
	color: #393d72;
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	margin: 0 0 6px;
}
.cost-calculator-box .title h3 .dotted {
	position: relative;
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
}
.cost-calculator-box .title p {
	margin: 0;
}

.calculator-form {
	position: relative;
	display: block;
}
.calculator-form .input-box {
	position: relative;
	display: block;
	margin-bottom: 10px;
}
.cost-calculator-box .bootstrap-select {
	position: relative;
	display: block;
	width: 100% !important;
	height: 50px;
}
.cost-calculator-box .bootstrap-select > .dropdown-toggle {
	position: relative;
	display: block;
	outline: none !important;
	border-radius: 14px;
	border: 0px solid #cccccc;
	background-color: #f4f2ef !important;
	max-width: 100%;
	width: 100%;
	height: 50px;
	margin: 0;
	padding: 0;
	padding-left: 30px;
	padding-right: 30px;
	color: #393d73 !important;
	font-size: 14px;
	line-height: 50px;
	font-weight: 700;
	letter-spacing: normal;
	text-transform: capitalize;
	box-shadow: none !important;
	font-family: 'Open Sans', sans-serif;
}
.cost-calculator-box .bootstrap-select .dropdown-toggle:focus {
	outline: none !important;
}
.cost-calculator-box .bootstrap-select .dropdown-menu li a span.text {
	display: inline-block;
	margin: 0;
}
.cost-calculator-box .bootstrap-select > .dropdown-toggle::after {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 28px;
	width: auto;
	height: auto;
	font-family: 'icomoon' !important;
	content: '\e93c';
	font-size: 14px;
	margin: 0;
	border: none;
}

.cost-calculator-box .bootstrap-select .dropdown-menu {
	margin: 0;
	padding: 0;
	border-radius: 14px;
	border: 0px solid #ddd;
	background: #eae8e4;
	font-size: 16px;
	color: #000000;
}
.cost-calculator-box .bootstrap-select .dropdown-menu li {
	position: relative;
	border-bottom: 1px solid #d7d7d7;
}
.cost-calculator-box .dropdown-item {
	display: block;
	width: 100%;
	padding: 9px 20px 9px;
	color: #222222;
	font-size: 14px;
	font-weight: 700;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	transition: all 500ms ease;
	font-family: 'Open Sans', sans-serif;
}

.calculating-box {
	position: relative;
	display: block;
	padding-top: 15px;
}
.calculating-box .bar {
	position: relative;
	display: block;
	width: 100%;
	height: 6px;
	background: #f4f2ef;
	border-radius: 10px;
}
.calculating-box .bar .inner {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 30%;
	content: '';
	border-radius: 10px;
}
.calculating-box .bar .inner .value {
	position: absolute;
	top: -15px;
	right: 0;
	bottom: 0;
	height: 40px;
	width: 50px;
	border-radius: 7px;
	color: #ffffff;
	font-size: 14px;
	line-height: 40px;
	font-weight: 700;
	text-align: center;
}

.calculating-box .text {
	position: relative;
	padding-top: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	flex-wrap: wrap;
}
.calculating-box .text .left {
	position: relative;
}
.calculating-box .text .left p {
	color: #393d72;
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
	margin: 0;
}
.calculating-box .text .right {
	position: relative;
}
.calculating-box .text .right p {
	color: #393d72;
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
	margin: 0;
}

/*** 
=============================================
    Service Style2 Area
=============================================
***/
.service-style2-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 138px 0 90px;
}
.service-style2-area .zikjak {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 18px;
	background-repeat: repeat-x;
	opacity: 0.1;
	animation: slide 10s linear infinite;
	-webkit-animation: slide 10s linear infinite;
}
@keyframes slide {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -111px 0;
	}
}

@-webkit-keyframes slide {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -111px 0;
	}
}

.single-service-style2 {
	position: relative;
	display: block;
	background: #ffffff;
	margin-bottom: 30px;
	z-index: 2;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.single-service-style2 .inner-box {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 30px 30px 35px;
	padding-right: 20px;
}
.single-service-style2 .inner-box .big-text {
	position: absolute;
	top: -5px;
	right: -12px;
	color: #fafafd;
	font-size: 160px;
	line-height: 130px;
	font-weight: 700;
	transform: scaleX(0);
	transform-origin: center;
	transform-style: preserve-3d;
	font-family: 'Poppins', sans-serif;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.single-service-style2:hover .inner-box .big-text {
	transform: scaleX(1);
}
.single-service-style2:hover {
	-webkit-box-shadow: 0px 2px 8px 2px rgba(20, 33, 43, 0.05);
	box-shadow: 0px 2px 8px 2px rgba(20, 33, 43, 0.05);
}
.single-service-style2:before {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border: 2px solid #f2f2f6;
	content: '';
	z-index: -1;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.single-service-style2:hover:before {
	border: 2px solid transparent;
}
.single-service-style2:after {
	position: absolute;
	left: 0;
	bottom: -4px;
	right: 0;
	height: 4px;
	content: '';
	z-index: 1;
	transform: scaleX(0);
	transform-origin: center;
	transform-style: preserve-3d;
	transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.22);
}
.single-service-style2:hover:after {
	transform: scaleX(1);
}
.single-service-style2 .icon-holder {
	position: relative;
	display: block;
}
.single-service-style2 .icon-holder img {
	width: auto;
}

.single-service-style2 .text-holder {
	position: relative;
	display: block;
	padding-top: 24px;
}
.single-service-style2 .text-holder h3 {
	font-size: 22px;
	line-height: 34px;
	font-weight: 700;
	margin: 0 0 5px;
}
.single-service-style2 .text-holder h3 a {
	color: #393d72;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.single-service-style2 .text-holder p {
	color: #393d72;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	margin: 0;
}
.single-service-style2 .text-holder .button {
	position: relative;
	display: block;
	padding-top: 10px;
}
.single-service-style2 .text-holder .button a {
	color: #acadc0;
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
	text-decoration: underline;
	font-family: 'Poppins', sans-serif;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

/*** 
=============================================
    Feautres Style2 Area Css
=============================================
***/
.feautres-style2-area {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding: 120px 0 120px;
}
.feautres-style2-image-box {
	position: relative;
	display: block;
}
.feautres-style2-image-box .image-inner {
	position: relative;
	display: block;
	overflow: hidden;
}
.feautres-style2-image-box .image-inner::after {
	position: absolute;
	top: 0;
	left: -100%;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
	transform: skewX(-25deg);
	z-index: 2;
}
.feautres-style2-image-box:hover .image-inner::after {
	-webkit-animation: shine 1.5s;
	animation: shine 1.5s;
}

.feautres-style2-image-box img {
	width: 100%;
}
.feautres-style2-image-box .outer-image {
	position: absolute;
	left: -150px;
	bottom: 0;
	z-index: 10;
}
.feautres-style2-image-box .outer-image img {
	width: auto;
}

.feautres-style2-content-box {
	position: relative;
	display: block;
	padding-top: 50px;
	padding-left: 30px;
}
.feautres-style2-content-box .sec-title {
	padding-bottom: 40px;
}
.feautres-style2-content-box .sec-title h2 {
	font-size: 50px;
	margin: 11px 0 0;
}
.feautres-style2-content-box .inner-content {
	position: relative;
	display: block;
}
.feautres-style2-content-box .inner-content ul {
	overflow: hidden;
}
.feautres-style2-content-box .inner-content ul li {
	position: relative;
	display: block;
	padding-left: 90px;
	min-height: 90px;
	margin-bottom: 30px;
}
.feautres-style2-content-box .inner-content ul li:last-child {
	margin-bottom: 0;
}
.feautres-style2-content-box .inner-content ul li .icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 90px;
	height: 90px;
	background: #ffffff;
	border-radius: 15px;
}
.feautres-style2-content-box .inner-content ul li .text {
	padding-left: 30px;
}
.feautres-style2-content-box .inner-content ul li .text h3 {
	color: #393d72;
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
	margin: 0 0 4px;
}
.feautres-style2-content-box .inner-content ul li .text p {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	margin: 0;
}

/*** 
=============================================
    Faq Style1 Area Css
=============================================
***/
.faq-style1-area {
	position: relative;
	display: block;
	padding: 120px 0 120px;
}
.faq-style1-area.gray-bg {
	background: #f4f2ef;
	padding-bottom: 180px;
}

.faq-left-content {
	position: relative;
	display: block;
	margin-right: 30px;
}
.faq-style1-area .sec-title {
	padding-bottom: 48px;
}

.faq-style1-image-box {
	position: relative;
	display: block;
	padding-top: 115px;
	padding-left: 70px;
	padding-bottom: 70px;
}
.faq-style1-image-box .faq-main-image {
	position: relative;
	display: block;
	max-width: 435px;
	width: 100%;
	z-index: 2;
}
.faq-style1-image-box .faq-main-image .box {
	position: absolute;
	left: -20px;
	bottom: -20px;
	width: 40px;
	height: 40px;
}

.faq-style1-image-box .faq-main-image img {
	width: 100%;
}

.faq-style1-image-box .faq-top {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
}
.faq-style1-image-box .faq-bottom {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;
}

.faq-left-content.style2 {
	margin-left: 30px;
	margin-right: 0;
}

/*** 
=============================================
    Offers Area Css
=============================================
***/
.offers-area {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding: 120px 0 120px;
}
.anim-shape {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}
.offers-area .shape {
	position: absolute;
	background-repeat: no-repeat;
	background-position: left;
	background-image: url(../images/shape/shape-3.png);
	width: 798px;
	height: 730px;
}
.offers-image-box {
	position: relative;
	display: block;
	width: 100%;
	min-height: 587px;
}
.offers-image-box .image-one {
	position: absolute;
	top: -20px;
	left: 500px;
}
.offers-image-box .image-two {
	position: absolute;
	left: 0px;
	bottom: 0;
}

.offers-content-box {
	position: relative;
	display: block;
	max-width: 720px;
	width: 100%;
	background: #ffffff;
	padding: 75px 50px 80px;
	border-radius: 25px;
}
.offers-content-box h2 {
	font-size: 100px;
	line-height: 90px;
	font-weight: 700;
	margin: 0 0 16px;
}
.offers-content-box h2 span {
	position: relative;
	top: -2px;
	font-size: 20px;
	line-height: 0;
}
.offers-content-box .big-title {
	color: #393d72;
	font-size: 60px;
	line-height: 70px;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
	margin: 0 0 15px;
}
.offers-content-box p {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	margin: 0;
}
.offers-content-box .button {
	position: relative;
	display: block;
	padding-top: 33px;
}
.offers-content-box .button a {
	line-height: 60px;
}
.offers-content-box .button a:after {
	display: none;
}
.offers-content-box .button a i {
	position: relative;
	display: inline-block;
	float: left;
	font-size: 16px;
	line-height: 60px;
	padding-right: 10px;
}

/*** 
=============================================
    Choose Style1 Area Css
=============================================
***/
.choose-style1-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 120px 0 120px;
}
.choose-style1-area.pdtop0 {
	padding-top: 0;
}
.choose-style1-area .feautres-content-box {
	float: left;
}
.choose-style1-image-box {
	position: relative;
	display: block;
	overflow: hidden;
}
.choose-style1-image-box::after {
	position: absolute;
	top: 0;
	left: -100%;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
	transform: skewX(-25deg);
	z-index: 2;
}
.choose-style1-image-box:hover::after {
	-webkit-animation: shine 1.5s;
	animation: shine 1.5s;
}

.choose-style1-image-box img {
	width: 100%;
}

/*** 
=============================================
    Home Map Area Css
=============================================
***/
.home-map-area {
	position: relative;
	display: block;
}
.home-map-outer {
	position: relative;
	display: block;
	max-width: 100%;
	width: 100%;
}
.home-map-outer .map-canvas {
	position: relative;
	width: 100%;
	height: 700px;
}

/*** 
=============================================
    Contact Info Area Css
=============================================
***/
.contact-info-area {
	position: relative;
	display: block;
	margin-top: -90px;
}
.contact-info-area.style2 {
	margin-top: 0;
	margin-bottom: -50px;
	z-index: 10;
}
.contact-info-area.style3 {
	padding-top: 80px;
	margin-top: 0;
	margin-bottom: -50px;
	z-index: 10;
}

.single-contact-info-box {
	position: relative;
	display: block;
	background: #ffffff;
	text-align: center;
	-webkit-box-shadow: 0px 4px 10px 2px rgba(20, 33, 43, 0.06);
	box-shadow: 0px 4px 10px 2px rgba(20, 33, 43, 0.06);
	padding: 61px 0 62px;
	border-radius: 0.5rem;
}

.single-contact-info-box span {
	font-size: 70px;
}
.single-contact-info-box .title {
	position: relative;
	display: block;
	margin: 32px 0 13px;
}
.single-contact-info-box .title h3 {
	color: #393d72;
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
}
.single-contact-info-box .title h3 .dotted {
	position: relative;
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
}

.single-contact-info-box ul {
	overflow: hidden;
}
.single-contact-info-box ul li {
	position: relative;
	display: block;
	line-height: 28px;
}
.single-contact-info-box ul li a {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.single-contact-info-box p {
	color: #393d72;
	font-size: 16px;
	line-height: 28px;
	font-weight: 700;
	margin: 0;
}

/*** 
=============================================
    Blog Style2 Area Css
=============================================
***/
.blog-style2-area {
	position: relative;
	display: block;
	padding: 120px 0 80px;
}

.single-blog-style1.style1instyle2 {
	position: relative;
	display: block;
	background: #ffffff;
	border: 2px solid #f2f2f6;
	margin-bottom: 40px;
	z-index: 1;
}
.single-blog-style1.style1instyle2 .text-holder {
	position: relative;
	display: block;
	padding: 35px 38px 36px;
	box-shadow: none;
	background: transparent;
}
.single-blog-style1.style1instyle2 .text-holder .meta-info li a {
	transition: all 200ms linear;
	transition-delay: 0.2s;
}
.single-blog-style1.style1instyle2:hover .text-holder .meta-info li a {
	color: #ffffff;
}

.single-blog-style1.style1instyle2 .text-holder .blog-title {
	font-size: 18px;
	line-height: 30px;
	margin: 7px 0 26px;
}
.single-blog-style1.style1instyle2 .text-holder .blog-title a {
	transition: all 200ms linear;
	transition-delay: 0.2s;
}
.single-blog-style1.style1instyle2:hover .text-holder .blog-title a {
	color: #ffffff;
}

.single-blog-style1.style1instyle2 .text-holder .text {
	position: relative;
	display: block;
	border-top: 1px solid #f2f2f6;
	padding-top: 22px;
}
.single-blog-style1.style1instyle2 .text-holder .text p {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	margin: 0;
	transition: all 200ms linear;
	transition-delay: 0.2s;
}
.single-blog-style1.style1instyle2:hover .text-holder .text p {
	color: #ffffff;
}

.single-blog-style1.style1instyle2 .overlay-bg {
	position: absolute;
	top: -2px;
	left: -2px;
	bottom: -2px;
	right: -2px;
	z-index: -1;
	background-attachment: scroll;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	transform: scaleX(0);
	transform-origin: center;
	transform-style: preserve-3d;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.single-blog-style1.style1instyle2:hover .overlay-bg {
	transform: scaleX(1);
}
.single-blog-style1.style1instyle2 .overlay-bg:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(40, 43, 85, 0.9);
	content: '';
}

/*** 
=============================================
    Footer Style2 Area style
=============================================
***/
.footer-style2-area {
	position: relative;
	display: block;
	background: #22254a;
}
.footer-top-content {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 80px 0 40px;
	border-bottom: 2px solid #353862;
}
.footer-top-content .footer-logo {
	margin: 0;
}

.footer-style2 {
	position: relative;
	display: block;
	padding: 74px 0px 75px;
}
.single-footer-widget .title {
	position: relative;
	display: block;
	padding-bottom: 30px;
}
.single-footer-widget .title h3 {
	color: #ffffff;
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
}
.single-footer-widget .title h3 span {
	position: relative;
	display: inline-block;
	height: 5px;
	width: 5px;
	border-radius: 50%;
}

.single-footer-widget {
	position: relative;
	display: block;
}
.single-footer-widget.pdleft30 {
	padding-left: 30px;
}
.single-footer-widget.pdleft50 {
	padding-left: 50px;
}
.single-footer-widget .widget-link1 {
	position: relative;
	display: block;
}
.single-footer-widget .widget-link1 li {
	margin: 0px 0px 20px;
}
.single-footer-widget .widget-link1 li:last-child {
	margin: 0;
}
.single-footer-widget .widget-link1 li a {
	color: #a5a7c2;
	font-size: 16px;
	font-weight: 600;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.single-footer-widget .widget-link1 li a:hover {
	color: #ffffff;
}

.single-footer-widget .widget-link2 {
	position: relative;
}
.single-footer-widget .widget-link2 li {
	margin: 0px 0px 20px;
}
.single-footer-widget .widget-link2 li:last-child {
	margin: 0;
}
.single-footer-widget .widget-link2 li a {
	color: #a5a7c2;
	font-size: 16px;
	font-weight: 600;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.single-footer-widget .widget-link2 li a:hover {
	color: #ffffff;
}

.widget-company-info {
	position: relative;
	display: block;
}
.widget-company-info p {
	color: #bdbfd8;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	margin: 0px;
}
.widget-company-info .readmore {
	position: relative;
	display: block;
	padding-top: 21px;
}
.widget-company-info .readmore a {
	font-size: 16px;
	font-weight: 700;
	text-decoration: underline;
}

.single-footer-widget .subscribe-box {
	position: relative;
	display: block;
	padding-top: 6px;
}
.single-footer-widget .subscribe-box .input-box {
	position: relative;
	display: block;
}
.single-footer-widget .subscribe-box .icon {
	position: absolute;
	right: 30px;
	bottom: 0;
	font-size: 15px;
	line-height: 50px;
}

.subscribe-widget-box {
	position: relative;
	display: block;
	max-width: 270px;
	width: 100%;
	float: right;
}
.subscribe-box {
	position: relative;
	display: block;
}
.subscribe-form {
	position: relative;
	display: block;
	width: 100%;
}
.subscribe-form .input-box {
	position: relative;
	display: block;
	margin-bottom: 10px;
}
.subscribe-form input[type='text'],
.subscribe-form input[type='email'] {
	position: relative;
	display: block;
	width: 100%;
	height: 50px;
	padding: 0 30px;
	padding-right: 50px;
	background: rgba(255, 255, 255, 1);
	border: 0px solid rgba(255, 255, 255, 0.2);
	color: #393d73;
	font-size: 14px;
	font-weight: 600;
	font-style: normal;
	border-radius: 10px;
	transition: all 500ms ease;
	font-family: 'Open Sans', sans-serif;
}
.subscribe-form input::-webkit-input-placeholder {
	color: #393d73;
}
.subscribe-form input:-moz-placeholder {
	color: #393d73;
}
.subscribe-form input::-moz-placeholder {
	color: #393d73;
}
.subscribe-form input:-ms-input-placeholder {
	color: #393d73;
}

.subscribe-form button {
	width: 100%;
	padding: 0;
	display: flex;
	justify-content: center;
}
.subscribe-form button:after {
	display: none;
}
.subscribe-form button i {
	position: relative;
	display: inline-block;
	float: left;
	font-size: 16px;
	line-height: 50px;
	padding-right: 10px;
}

.footer-bottom-style2 {
	position: relative;
	display: block;
	background: #171a39;
	padding: 25px 0;
}
.footer-bottom-style2 .outer-box {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	flex-wrap: wrap;
}
.footer-menu-style2 {
	position: relative;
	display: block;
	overflow: hidden;
}
.footer-menu-style2 ul {
	overflow: hidden;
}
.footer-menu-style2 ul li {
	position: relative;
	display: inline-block;
	float: left;
	line-height: 30px;
	margin-right: 50px;
}
.footer-menu-style2 ul li:last-child {
	margin-right: 0;
}
.footer-menu-style2 ul li a {
	color: #ffffff;
	font-size: 16px;
	font-weight: 700;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.payment-method-box {
	position: relative;
	display: block;
}
.payment-method-box ul {
	overflow: hidden;
}
.payment-method-box ul li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 20px;
}
.payment-method-box ul li:last-child {
	margin-right: 0;
}
.payment-method-box ul li a {
	display: inline-block;
}
.payment-method-box ul li a img {
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.payment-method-box ul li a:hover img {
	opacity: 0.7;
}

/*** 
=============================================
    Service Details Area Css
=============================================
***/
.service-details-area {
	position: relative;
	display: block;
	padding-top: 110px;
	padding-bottom: 120px;
}
.service-details-content {
	position: relative;
	display: block;
	padding-right: 20px;
}

.service-details-main-image {
	position: relative;
	display: block;
}
.service-details-main-image img {
	width: 100%;
}
.service-details-main-image .overlay-box {
	position: absolute;
	right: 30px;
	bottom: -50px;
	background: #ffffff;
	width: 280px;
	border-radius: 20px;
	box-shadow: 0px 6px 12px 0px rgba(20, 33, 43, 0.03);
	display: flex;
	align-items: center;
	padding: 21px 40px 22px;
}
.service-details-main-image .overlay-box .icon {
	position: relative;
	display: block;
	width: 35px;
}
.service-details-main-image .overlay-box .icon span {
	font-size: 42px;
}
.service-details-main-image .overlay-box .title {
	position: relative;
	display: block;
	padding-left: 20px;
}
.service-details-main-image .overlay-box .title h3 {
	color: #393d72;
	font-size: 20px;
	line-height: 30px;
	font-weight: 700;
	margin: 0 0 1px;
}
.service-details-main-image .overlay-box .title p {
	margin: 0;
}

.service-details-text-box {
	position: relative;
	display: block;
	margin-top: 53px;
	margin-bottom: 53px;
}
.service-details-text-box h2 {
	color: #393d72;
	font-size: 60px;
	line-height: 70px;
	font-weight: 700;
	margin-bottom: 20px;
}
.service-details-text-box h2 .dotted {
	position: relative;
	display: inline-block;
	height: 13px;
	width: 13px;
	border-radius: 50%;
}
.service-details-text-box p {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	margin: 0 0 21px;
}

.service-details-bottom-image {
	position: relative;
	display: block;
}
.service-details-bottom-image .single-image-nox {
	position: relative;
	display: block;
	overflow: hidden;
	margin-bottom: 30px;
}
.service-details-bottom-image .single-image-nox::after {
	position: absolute;
	top: 0;
	left: -100%;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
	transform: skewX(-25deg);
	z-index: 2;
}
.service-details-bottom-image .single-image-nox:hover::after {
	-webkit-animation: shine 1.5s;
	animation: shine 1.5s;
}

.service-details-bottom-image .single-image-nox img {
	width: 100%;
	border-radius: 20px;
}

.service-details-bottom-text {
	position: relative;
	display: block;
	padding-top: 24px;
}
.service-details-bottom-text p {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
}

.service-details-sidebar {
	position: relative;
	display: block;
}
.service-details-categories {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding: 33px 35px 28px;
	border-radius: 20px;
}
.service-details-sidebar .title {
	position: relative;
	display: block;
	margin-bottom: 17px;
}
.service-details-sidebar .title h3 {
	color: #393d72;
	font-size: 20px;
	line-height: 30px;
	font-weight: 700;
}
.service-details-sidebar .title h3 .dotted {
	position: relative;
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
}

.categories-box {
	position: relative;
	display: block;
}
.categories-box ul {
	overflow: hidden;
}
.categories-box ul li {
	margin-bottom: 8px;
}
.categories-box ul li:last-child {
	margin-bottom: 0;
}
.categories-box ul li a {
	position: relative;
	display: block;
	color: #393d72;
	font-size: 16px;
	line-height: 45px;
	font-weight: 700;
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
	z-index: 1;
}
.categories-box ul li.active a,
.categories-box ul li a:hover {
	padding-left: 20px;
}
.categories-box ul li a:before {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background: #ffffff;
	border-radius: 8px;
	content: '';
	z-index: -1;
	transform: scaleX(0);
	transform-origin: right;
	transform-style: preserve-3d;
	transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.22);
}
.categories-box ul li.active a:before,
.categories-box ul li a:hover:before {
	transform: scaleX(1);
}

.callto-action-box {
	position: relative;
	display: block;
	border-radius: 20px;
	background-size: cover;
	background-position: center center;
	background-attachment: scroll;
	margin-top: 30px;
	padding: 64px 0 70px;
	z-index: 1;
}
.callto-action-box:before {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border-radius: 20px;
	content: '';
	z-index: -1;
}
.callto-action-box p {
	color: #ffffff;
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
	margin: 0;
}
.callto-action-box h3 {
	color: #ffffff;
	font-size: 24px;
	line-height: 34px;
	font-weight: 700;
	margin: 6px 0 21px;
}
.callto-action-box .btn-one {
	background: #d42a5e;
}

.servicedet-prev-next-option {
	position: relative;
	display: block;
	width: 100%;
	margin-top: 37px;
}
.servicedet-prev-next-option .box {
	position: relative;
	display: flex;
	align-content: center;
	width: 50%;
	float: left;
	padding: 20px;
}
.servicedet-prev-next-option .box .inner {
	position: relative;
	display: flex;
	align-items: center;
}
.servicedet-prev-next-option .box .inner-next {
	position: relative;
	display: inline-flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.servicedet-prev-next-option .box.prev {
	background: #22254a;
}
.servicedet-prev-next-option .box.next {
	display: block;
	float: right;
	text-align: right;
}
.servicedet-prev-next-option .box.next .title {
	padding-left: 0;
	padding-right: 20px;
}

.servicedet-prev-next-option .box .image {
	width: 130px;
}
.servicedet-prev-next-option .box .title {
	padding-left: 20px;
}
.servicedet-prev-next-option .box .title p {
	font-size: 14px;
	line-height: 16px;
	font-weight: 700;
	text-transform: capitalize;
	margin: 0 0 8px;
}
.servicedet-prev-next-option .box .title p a {
	color: #ffffff;
}
.servicedet-prev-next-option .box .title h3 {
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
}
.servicedet-prev-next-option .box .title h3 a {
	color: #ffffff;
}

/*** 
=============================================
    Breadcrumb area style
=============================================
***/
.breadcrumb-area {
	position: relative;
	display: block;
	overflow: hidden;
	background-attachment: scroll;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 298px 0 180px;
	z-index: 10;
}
.filter {
	filter: brightness(0.8);
	position: absolute;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: -1;
}
.breadcrumb-area.style2 {
	padding-top: 228px;
}

.breadcrumb-area .banner-curve {
	position: absolute;
	left: 0;
	bottom: -2px;
	width: 100%;
	height: 90px;
	background-image: url(../images/shape/banner-curve.png);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 100% 100%;
	z-index: 2;
}
.breadcrumb-area .banner-curve-gray {
	position: absolute;
	left: 0;
	bottom: -2px;
	width: 100%;
	height: 90px;
	background-image: url(assets/images/shape/banner-curve-gray.png);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 100% 100%;
	z-index: 2;
}
.breadcrumb-area.banner-gray {
	background-color: #f4f2ef;
}
.breadcrumb-area .inner-content {
	position: relative;
	display: block;
}
.breadcrumb-area .title {
	position: relative;
	display: block;
}
.breadcrumb-area .title h2 {
	color: #ffffff;
	font-size: 67px;
	line-height: 1.1em;
	font-weight: 700;
	text-transform: capitalize;
}
.breadcrumb-area .title h2 span {
	position: relative;
	display: inline-block;
	height: 16px;
	width: 16px;
	border-radius: 50%;
}

.breadcrumb-menu ul {
	position: relative;
	display: block;
	overflow: hidden;
	padding-top: 9px;
}
.breadcrumb-menu ul li {
	position: relative;
	display: inline-block;
	color: #ffffff;
	font-size: 16px;
	line-height: 30px;
	font-weight: 700;
	margin-right: 10px;
	padding-right: 16px;
	text-transform: capitalize;
}
.breadcrumb-menu ul li:after {
	position: absolute;
	right: 0;
	top: 8px;
	width: 1px;
	height: 15px;
	background: #ffffff;
	content: '';
}
.breadcrumb-menu ul li:last-child:after {
	display: none;
}
.breadcrumb-menu ul li:last-child {
	margin: 0;
	padding: 0;
}
.breadcrumb-menu ul li a {
	color: #ffffff;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

/*** 
=============================================
    Team Page Area Css
=============================================
***/
.team-page-area {
	position: relative;
	display: block;
	padding: 65px 0 0;
}
.single-filter-box {
	position: relative;
	display: block;
	border: 2px solid #f4f2ef;
	border-radius: 14px;
	padding: 40px 0px 32px;
	margin-bottom: 63px;
}
.single-filter-box span {
	font-size: 60px;
}
.single-filter-box h3 {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
	margin-top: 7px;
}

.single-team-member.style2 .img-holder {
	background: #f4f2ef;
}
.single-team-member.style2 .img-holder .round-top {
	background: #f4f2ef;
}
.single-team-member.style2:hover .img-holder .round-top {
	background: #f9be4f;
}
.single-team-member.style2 .img-holder .round-bottom {
	background: #f4f2ef;
}

/*** 
=============================================
    showcase Area Css
=============================================
***/
.showcase-area {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding: 0 0 30px;
}
.single-showcase-box {
	position: relative;
	display: block;
}
.single-showcase-box .img-holder {
	position: relative;
	display: block;
	overflow: hidden;
	border-radius: 20px;
	margin-bottom: 30px;
}
.single-showcase-box .img-holder img {
	width: 100%;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.4s ease;
	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
.single-showcase-box:hover .img-holder img {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}

/*** 
=============================================
    Skillset Area Css
=============================================
***/
.skillset-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 120px 0 120px;
}
.skillset-progress-box {
	position: relative;
	display: block;
}
.skillset-progress-box:before {
	position: absolute;
	top: 50px;
	left: 50%;
	height: 100px;
	width: 1px;
	background: #f4f2ef;
	content: '';
}

.progress-block .inner-box {
	position: relative;
	width: 200px;
	min-height: 200px;
	text-align: center;
	margin: 0 auto 21px;
}
.progress-block .graph-outer {
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	text-align: center;
	background: #fff;
	border-radius: 50%;
}
.progress-block .graph-outer .count-box {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 100%;
	font-size: 16px;
	font-weight: 700;
}

.progress-block .graph-outer .count-text {
	position: relative;
	display: inline-block;
	color: #393d72;
	font-size: 80px;
	line-height: 70px;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
}
.progress-block .title {
	position: relative;
	display: block;
}
.progress-block .title h2 {
	color: #393d72;
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
	margin: 0 0 4px;
}
.progress-block .title p {
	color: #393d72;
	font-size: 16px;
	font-weight: 600;
	margin: 0;
}

.skillset-content-box {
	position: relative;
	display: block;
}
.skillset-content-box .sec-title {
	padding-bottom: 22px;
}
.skillset-content-box .text {
	position: relative;
	display: block;
	padding-bottom: 7px;
}
.skillset-content-box .text p {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
}
.skillset-content-box .bottom-text {
	position: relative;
	display: block;
}
.skillset-content-box .bottom-text .img-box {
	width: 110px;
}
.skillset-content-box .bottom-text .img-box img {
	width: 100%;
}
.skillset-content-box .bottom-text .img-box,
.skillset-content-box .bottom-text .text-box {
	display: table-cell;
	vertical-align: middle;
}
.skillset-content-box .bottom-text .text-box {
	position: relative;
	padding-left: 30px;
}
.skillset-content-box .bottom-text .text-box p {
	color: #393d72;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	margin: 0;
}

/*** 
=============================================
    Fact Counter Area style
=============================================
***/
.fact-counter-area {
	position: relative;
	display: block;
	margin-bottom: -50px;
	z-index: 10;
}
.fact-counter-box {
	position: relative;
	display: block;
	background: #393d72;
	padding: 57px 0 3px;
	border-radius: 30px;
}

.single-fact-counter {
	position: relative;
	display: block;
	margin-bottom: 50px;
}
.single-fact-counter .count-box h2 {
	color: #ffffff;
	font-size: 50px;
	line-height: 46px;
	font-weight: 700;
}
.single-fact-counter .count-box h2 span {
	display: inline-block;
}
.single-fact-counter .count-box h2 i:before {
	position: relative;
	display: inline-block;
	font-size: 10px;
	line-height: 10px;
	top: -2px;
	left: -9px;
}
.single-fact-counter .count-box h2 .orange {
	color: #ffb848;
}
.single-fact-counter .count-box h2 .blues {
	color: #55a1ff;
}
.single-fact-counter .count-box h5 {
	color: #ffffff;
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
	margin: 8px 0px 0px -15px;
	font-family: 'Open Sans', sans-serif;
}

/*** 
=============================================
    Contact Form Style1 Area Css
=============================================
***/
.contact-form-style1-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 170px 0 120px;
	z-index: 1;
}
.contact-form-style1-bg {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: -1;
}

.contact-form {
	position: relative;
	display: block;
	width: 100%;
}
.contact-form form {
	position: relative;
	display: block;
}
.contact-form form .input-box {
	position: relative;
	display: block;
	margin-bottom: 20px;
}
.contact-form form .input-box .icon {
	position: absolute;
	top: 50%;
	right: 30px;
	transform: translateY(-50%);
	font-size: 16px;
	z-index: 10;
}

.contact-form form input[type='text'],
.contact-form form input[type='email'],
.contact-form form textarea {
	position: relative;
	display: block;
	background: #ffffff;
	border: 1px solid #ffffff;
	width: 100%;
	height: 60px;
	color: #393d73;
	font-size: 14px;
	font-weight: 600;
	font-style: normal;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 10px;
	transition: all 500ms ease;
	font-family: 'Open Sans', sans-serif;
}
.contact-form form textarea {
	height: 200px;
	padding-top: 15px;
	padding-left: 30px;
	padding-right: 30px;
}
.contact-form form input[type='text']:focus {
	color: #222222;
	border-color: #101f42;
}
.contact-form form input[type='email']:focus {
	color: #222222;
	border-color: #101f42;
}
.contact-form form textarea:focus {
	color: #222222;
	border-color: #101f42;
}

.contact-form form input[type='text']::-webkit-input-placeholder {
	color: #393d73;
}
.contact-form form input[type='text']:-moz-placeholder {
	color: #393d73;
}
.contact-form form input[type='text']::-moz-placeholder {
	color: #393d73;
}
.contact-form form input[type='text']:-ms-input-placeholder {
	color: #393d73;
}
.contact-form form input[type='email']::-webkit-input-placeholder {
	color: #393d73;
}
.contact-form form input[type='email']:-moz-placeholder {
	color: #393d73;
}
.contact-form form input[type='email']::-moz-placeholder {
	color: #393d73;
}
.contact-form form input[type='email']:-ms-input-placeholder {
	color: #393d73;
}
.contact-form form textarea::-webkit-input-placeholder {
	color: #393d73;
}
.contact-form form textarea:-moz-placeholder {
	color: #393d73;
}
.contact-form form textarea::-moz-placeholder {
	color: #393d73;
}
.contact-form form textarea:-ms-input-placeholder {
	color: #393d73;
}

.contact-form form .bootstrap-select {
	position: relative;
	display: block;
	width: 100% !important;
	height: 60px;
}
.contact-form form .bootstrap-select > .dropdown-toggle {
	position: relative;
	display: block;
	outline: none !important;
	border-radius: 10px;
	border: 0px solid #ffffff;
	background-color: #ffffff !important;
	max-width: 100%;
	width: 100%;
	height: 60px;
	margin: 0;
	padding: 0;
	padding-left: 30px;
	padding-right: 30px;
	color: #393d73 !important;
	font-size: 14px;
	line-height: 60px;
	font-weight: 600;
	letter-spacing: normal;
	text-transform: capitalize;
	box-shadow: none !important;
	font-family: 'Open Sans', sans-serif;
}
.contact-form form .bootstrap-select .dropdown-toggle:focus {
	outline: none !important;
}
.contact-form form .bootstrap-select .dropdown-menu li a span.text {
	display: inline-block;
	margin: 0;
}
.contact-form form .bootstrap-select > .dropdown-toggle::after {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 28px;
	width: auto;
	height: auto;
	font-family: 'icomoon' !important;
	content: '\e93c';
	font-size: 14px;
	margin: 0;
	border: none;
}

.contact-form form .bootstrap-select .dropdown-menu {
	margin: 0;
	padding: 0;
	border-radius: 14px;
	border: 0px solid #ddd;
	background: #eae8e4;
	font-size: 16px;
	color: #000000;
}
.contact-form form .bootstrap-select .dropdown-menu li {
	position: relative;
	border-bottom: 1px solid #d7d7d7;
}
.contact-form form .dropdown-item {
	display: block;
	width: 100%;
	padding: 9px 20px 9px;
	color: #222222;
	font-size: 14px;
	font-weight: 700;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	transition: all 500ms ease;
	font-family: 'Open Sans', sans-serif;
}

.contact-form form .button-box {
	position: relative;
	display: block;
	padding-top: 10px;
}
.contact-form form .button-box button {
	line-height: 60px;
}
.contact-form form .button-box button i {
	position: relative;
	display: inline-block;
	float: left;
	font-size: 16px;
	line-height: 60px;
	padding-right: 10px;
}

/*** 
=============================================
   Shop Area  style
=============================================
***/
.shop-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding-top: 85px;
	padding-bottom: 80px;
}
.shop-content {
	position: relative;
	display: block;
}

.showing-result-shorting {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 5px;
	border-bottom: 1px solid #e6e6e6;
	margin-bottom: 40px;
}
.showing-result-shorting .showing {
}
.showing-result-shorting .showing p {
	color: #22254a;
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
	margin: 0;
	font-family: 'Open Sans', sans-serif;
}

.showing-result-shorting .shorting {
}
.shorting .bootstrap-select {
	position: relative;
	display: block;
	width: 170px !important;
	height: 60px;
}
.shorting .bootstrap-select > .dropdown-toggle {
	position: relative;
	display: block;
	outline: none !important;
	border-radius: 14px;
	border: 0px solid #cccccc;
	background-color: transparent !important;
	max-width: 100%;
	width: 100%;
	height: 60px;
	margin: 0;
	padding: 0;
	padding-left: 0px;
	padding-right: 20px;
	color: #22254a !important;
	font-size: 14px;
	line-height: 60px;
	font-weight: 700;
	letter-spacing: normal;
	text-transform: capitalize;
	box-shadow: none !important;
	font-family: 'Open Sans', sans-serif;
}
.shorting .bootstrap-select .dropdown-toggle:focus {
	outline: none !important;
}
.shorting .bootstrap-select .dropdown-menu li a span.text {
	display: inline-block;
	margin: 0;
}
.shorting .bootstrap-select > .dropdown-toggle::after {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0px;
	width: auto;
	height: auto;
	font-family: 'icomoon' !important;
	content: '\e93c';
	color: #c9c5bf;
	font-size: 14px;
	margin: 0;
	border: none;
}

.shorting .bootstrap-select .dropdown-menu {
	margin: 0;
	padding: 0;
	border-radius: 14px;
	border: 0px solid #ddd;
	background: #eae8e4;
	font-size: 16px;
	color: #000000;
}
.shorting .bootstrap-select .dropdown-menu li {
	position: relative;
	border-bottom: 1px solid #d7d7d7;
}
.shorting .dropdown-item {
	display: block;
	width: 100%;
	padding: 9px 20px 9px;
	color: #222222;
	font-size: 14px;
	font-weight: 700;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	transition: all 500ms ease;
	font-family: 'Open Sans', sans-serif;
}

.single-product-item {
	position: relative;
	display: block;
	margin-bottom: 15px;
}
.single-product-item .img-holder {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-product-item .img-holder img {
	transform: scale(1.05);
	-webkit-transition: all 0.4s linear;
	transition: all 0.4s linear;
	width: 100%;
}
.single-product-item:hover .img-holder img {
	transform: scale(1);
}

.single-product-item .img-holder .overlay-content {
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	content: '';
	opacity: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	opacity: 0;
	transform: translateY(100%);
	z-index: 5;
	-webkit-transition: all 0.6s linear;
	transition: all 0.6s linear;
}
.single-product-item:hover .img-holder .overlay-content {
	opacity: 1;
	transform: translateY(0px);
}
.single-product-item .img-holder .overlay-content a {
	opacity: 0;
	padding: 0 20px;
	font-weight: 500;
	transform: translateY(-50px);
	transition: all 500ms ease 300ms;
}
.single-product-item:hover .img-holder .overlay-content a {
	opacity: 1;
	transform: translateY(0px);
	transition: all 500ms ease 600ms;
}
.single-product-item .img-holder .overlay-content a span:before {
	position: relative;
	display: inline-block;
	padding-left: 7px;
	line-height: 20px;
}

.single-product-item .title-holder {
	position: relative;
	display: block;
	padding: 14px 0 0;
}
.single-product-item .title-holder h4 {
	font-size: 18px;
	line-height: 28px;
	font-weight: 700;
	margin: 0 0 1px;
}
.single-product-item .title-holder h4 a {
	color: #22254a;
}
.single-product-item .title-holder .price-box {
	position: relative;
	display: block;
}
.single-product-item .title-holder .price-box span {
	position: relative;
	display: inline-block;
	padding-right: 10px;
	font-size: 18px;
	line-height: 20px;
	font-weight: 700;
}
.single-product-item .title-holder .price-box del {
	position: relative;
	display: inline-block;
	color: #b5c2d1;
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
}

.sidebar-box-style2 {
	position: relative;
	display: block;
	margin-top: 22px;
}
.single-sidebar-style2 {
	position: relative;
	display: block;
	border: 2px solid #efefef;
	padding: 35px 38px 30px;
	margin-bottom: 30px;
}
.single-sidebar-style2 .title {
	position: relative;
	display: block;
	padding-bottom: 18px;
}
.single-sidebar-style2 .title h4 {
	color: #22254a;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
}

.filter-checkbox {
	position: relative;
	display: block;
}
.filter-checkbox li {
	position: relative;
	display: block;
	margin-bottom: 5px;
}
.filter-checkbox li:last-child {
	margin-bottom: 0;
}
.filter-checkbox .single-checkbox {
	position: relative;
	display: block;
}
.filter-checkbox label {
	position: relative;
	display: block;
	padding-left: 30px;
	margin-right: 0px;
	margin-bottom: 0;
	color: #22254a;
	font-size: 14px;
	line-height: 30px;
	font-weight: 700;
	cursor: pointer;
	font-family: 'Open Sans', sans-serif;
}
.filter-checkbox input[type='checkbox'] {
	display: none;
}
.filter-checkbox input[type='checkbox'] + label span {
	position: absolute;
	display: block;
	top: 7px;
	left: 0;
	width: 15px;
	height: 15px;
	vertical-align: middle;
	background-color: transparent;
	border: 1px solid #cccccc;
	cursor: pointer;
	border-radius: 0;
	transition: all 300ms ease;
}
.filter-checkbox label span:before {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	font-family: FontAwesome;
	content: '\f00c';
	font-size: 10px;
	line-height: 13px;
	text-align: center;
	border-radius: 50%;
	opacity: 0;
	background-color: transparent;
	transition: all 300ms ease;
}
.filter-checkbox input[type='checkbox']:checked + label span:before {
	opacity: 1;
}

.single-sidebar-style2 .size-filter {
	position: relative;
	display: block;
}
.single-sidebar-style2 .size-filter li {
	position: relative;
	display: block;
	color: #22254a;
	font-size: 14px;
	line-height: 30px;
	font-weight: 700;
	margin-bottom: 5px;
	cursor: pointer;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.single-sidebar-style2 .size-filter li:last-child {
	margin: 0;
	padding: 0;
}

.single-sidebar-style2 .price-ranger {
	margin-top: 11px;
}
.single-sidebar-style2 .price-ranger .ui-widget-content {
	background: #cbcbcb;
	border: none;
	height: 2px;
}
.single-sidebar-style2 .price-ranger .ui-slider-handle {
	border: 0px solid #69ad07;
	border-radius: 0%;
	height: 10px;
	width: 10px !important;
	margin-left: -4px;
	outline: medium none;
	cursor: pointer;
}
.single-sidebar-style2 .price-ranger .ui-slider-horizontal .ui-slider-handle {
	top: -4px;
}
.single-sidebar-style2 .price-ranger #slider-range {
	margin-left: 3px;
	margin-right: 5px;
	margin-top: 0;
}
.single-sidebar-style2 .price-ranger .ranger-min-max-block {
	margin-bottom: 0px;
	margin-top: 15px;
}
.single-sidebar-style2 .price-ranger .ranger-min-max-block input {
	display: inline-block;
}

.single-sidebar-style2 .price-ranger .ranger-min-max-block input[type='text'] {
	border: medium none;
	font-size: 14px;
	font-weight: 700;
	outline: medium none;
	text-align: left;
	width: 40px;
}
.single-sidebar-style2 .price-ranger .ranger-min-max-block input[type='text'].max {
}
.single-sidebar-style2 .price-ranger .ranger-min-max-block span {
	color: #cbcbcb;
	font-weight: 400;
	font-size: 16px;
	line-height: 0px;
}
.single-sidebar-style2 .price-ranger .ranger-min-max-block span.line {
	position: relative;
	display: inline-block;
	width: 5px;
	height: 1px;
	background: #cbcbcb;
	top: -4px;
	left: -8px;
}

.single-sidebar-style2 .price-ranger .ranger-min-max-block input[type='submit'] {
	float: right;
	text-align: right;
	border: none;
	color: #22254a;
	font-size: 14px;
	line-height: 26px;
	font-weight: 700;
	cursor: pointer;
	background: transparent;
}
.single-sidebar-style2 .price-ranger .ranger-min-max-block input[type='submit']:hover {
}

.single-sidebar-style2 .tag-filter {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-sidebar-style2 .tag-filter li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 9px;
}
.single-sidebar-style2 .tag-filter li a {
	color: #999999;
	font-size: 13px;
	font-weight: 700;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.shop-sidebar-image-box {
	position: relative;
	display: block;
}
.shop-sidebar-image-box img {
	width: 100%;
}

/*** 
=============================================
    Shop Details Area Css
=============================================
***/
.shop-details-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 110px 0 110px;
}

.single-product-image-holder {
	position: relative;
	display: block;
	max-width: 440px;
	width: 100%;
}
.single-product-image-holder .bx-wrapper {
	position: relative;
	margin: 0;
	padding: 0;
}
.single-product-image-holder .bx-wrapper .bx-viewport {
	box-shadow: none;
	border: 0px solid;
	left: 0px;
	background: #fff;
	transform: translatez(0);
}

.single-product-image-holder .slider-content {
	position: relative;
	display: block;
}
.single-product-slide {
	position: relative;
	display: block;
}
.single-product-slide .big-image-box {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-product-slide .big-image-box img {
	width: 100%;
	transition: 0.5s ease;
	transform: scale(1.05);
}
.single-product-slide:hover .big-image-box img {
	transform: scale(1);
}

.single-product-image-holder .slider-pager {
	position: relative;
	display: block;
	max-width: 440px;
	width: 100%;
	margin-top: 20px;
	text-align: center;
}
.single-product-image-holder .slider-pager .thumb-box {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-product-image-holder .slider-pager .thumb-box li {
	position: relative;
	display: inline-block;
	float: none;
	margin: 0 8px 13px;
}
.single-product-image-holder .slider-pager .thumb-box li a {
	position: relative;
	display: inline-block;
}
.single-product-image-holder .slider-pager .thumb-box li .img-holder {
	position: relative;
	display: block;
	overflow: hidden;
	z-index: 1;
}
.single-product-image-holder .slider-pager .thumb-box li .img-holder:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(143, 196, 36, 0.7);
	transition: all 200ms linear;
	transition-delay: 0.1s;
	opacity: 0;
	content: '';
}
.single-product-image-holder .slider-pager .thumb-box li a.active .img-holder:before {
	opacity: 1;
}

.single-product-info-box {
	position: relative;
	display: block;
}
.single-product-info-box .product-title {
	position: relative;
	display: block;
}
.single-product-info-box .product-title p {
	font-size: 14px;
	line-height: 18px;
	font-weight: 700;
	margin: 0 0 8px;
}
.single-product-info-box .product-title h2 {
	color: #22254a;
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
}

.single-product-info-box .product-value {
	position: relative;
	display: block;
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
	padding: 25px 0 28px;
	margin: 30px 0 34px;
}
.single-product-info-box .product-value h3 {
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
}
.single-product-info-box .product-value h3 del {
	color: #b2bfce;
	font-size: 18px;
}
.single-product-info-box .product-value .review-box {
	display: flex;
	align-items: center;
	margin-top: 11px;
}
.single-product-info-box .product-value .review-box span {
	position: relative;
	display: inline-block;
	padding-left: 5px;
	color: #d7d7d7;
	font-size: 14px;
	font-weight: 700;
}

.single-product-info-box .product-text {
	position: relative;
	display: block;
	border-bottom: 1px solid #dddddd;
	padding-bottom: 33px;
	margin-bottom: 36px;
}
.single-product-info-box .product-text .bottom {
	position: relative;
	display: block;
	padding-top: 12px;
}
.single-product-info-box .product-text p {
	color: #22254a;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	margin: 0;
}
.single-product-info-box .product-text ul {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-product-info-box .product-text ul li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 30px;
	color: #22254a;
	font-size: 16px;
	font-weight: 400;
}
.single-product-info-box .product-text ul li span {
	font-weight: 700;
}

.product-cart-box {
	position: relative;
	display: block;
}
.product-cart-box .input-box {
	position: relative;
	display: block;
	margin-bottom: 20px;
}
.product-cart-box .input-box .title {
	position: relative;
	display: block;
	padding-bottom: 15px;
}
.product-cart-box .input-box .title h4 {
	color: #22254a;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
}

.product-cart-box .bootstrap-select {
	position: relative;
	display: block;
	width: 100% !important;
	height: 50px;
}
.product-cart-box .bootstrap-select > .dropdown-toggle {
	position: relative;
	display: block;
	outline: none !important;
	border-radius: 0;
	border: 1px solid #e5e5e5;
	background-color: #ffffff !important;
	max-width: 100%;
	width: 100%;
	height: 50px;
	margin: 0;
	padding: 0;
	padding-left: 19px;
	padding-right: 20px;
	color: #999999 !important;
	font-size: 14px;
	line-height: 48px;
	font-weight: 700;
	letter-spacing: normal;
	text-transform: capitalize;
	box-shadow: none !important;
	font-family: 'Open Sans', sans-serif;
}
.product-cart-box .bootstrap-select .dropdown-toggle:focus {
	outline: none !important;
}
.product-cart-box .bootstrap-select .dropdown-menu li a span.text {
	display: inline-block;
	margin: 0;
}
.product-cart-box .bootstrap-select > .dropdown-toggle::after {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 28px;
	width: auto;
	height: auto;
	font-family: 'icomoon' !important;
	content: '\e941';
	color: #c9c5bf;
	font-size: 12px;
	margin: 0;
	border: none;
}

.product-cart-box .bootstrap-select .dropdown-menu {
	margin: 0;
	padding: 0;
	border-radius: 0px;
	border: 0px solid #ddd;
	background: #eae8e4;
	font-size: 16px;
	color: #000000;
}
.product-cart-box .bootstrap-select .dropdown-menu li {
	position: relative;
	border-bottom: 1px solid #d7d7d7;
}
.product-cart-box .dropdown-item {
	display: block;
	width: 100%;
	padding: 9px 20px 9px;
	color: #222222;
	font-size: 14px;
	font-weight: 700;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	transition: all 500ms ease;
	font-family: 'Open Sans', sans-serif;
}

.quantity-box .input-group.bootstrap-touchspin {
	float: left;
	width: 46px;
}
.quantity-box input.quantity-spinner.form-control {
	position: relative;
	display: block;
	height: 50px;
	width: 45px;
	padding: 0;
	transition: auto;
	color: #999999;
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	border: 1px solid #e5e5e5;
	font-family: 'Open Sans', sans-serif;
}
.quantity-box input.quantity-spinner.form-control:focus {
	box-shadow: none;
}

.quantity-box .bootstrap-touchspin .input-group-btn-vertical .btn {
	position: relative;
	display: block;
	float: none;
	margin-left: 0px;
	width: 100%;
	background: #f6f6f6;
	border: 1px solid #f6f6f6;
	color: #848484;
	padding: 12px 11px;
	cursor: pointer;
}
.quantity-box .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
	border-radius: 0;
}
.quantity-box .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
	border-radius: 0;
	margin-top: -2px;
}
.quantity-box .bootstrap-touchspin .input-group-btn-vertical .btn.bootstrap-touchspin-up:before {
	content: '\f0d8';
	font-family: FontAwesome;
	color: #848484;
	font-size: 14px;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	padding: 5px 5px 5px;
}
.quantity-box .bootstrap-touchspin .input-group-btn-vertical .btn.bootstrap-touchspin-down:before {
	content: '\f0d7';
	font-family: FontAwesome;
	color: #848484;
	font-size: 14px;
	position: absolute;
	top: -1px;
	left: 0px;
	width: 100%;
	height: 100%;
	padding: 0px 5px 5px;
}

.clear-selection {
	position: relative;
	display: block;
	float: right;
}
.clear-selection a {
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 50px;
	font-weight: 700;
	text-decoration: underline;
}

.product-details-button-box {
	position: relative;
	display: flex;
	align-content: center;
}
.product-details-button-box .addto-cart-button {
	position: relative;
	display: block;
}
.product-details-button-box .addto-cart-button button.addtocart {
	line-height: 62px;
	border-radius: 0;
}
.product-details-button-box .addto-cart-button button.addtocart:after {
	border-radius: 0px;
}

.product-details-button-box .wishlist-button {
	position: relative;
	display: block;
	margin-left: 30px;
}
.product-details-button-box .wishlist-button button.wishlist {
	color: #22254a;
	line-height: 58px;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 0;
	padding: 0 30px;
}
.product-details-button-box .wishlist-button button.wishlist:after {
	border-radius: 0px;
}

.product-details-button-box .compare-button {
	position: relative;
	display: block;
	margin-left: 30px;
}
.product-details-button-box .compare-button button.compare {
	color: #22254a;
	line-height: 58px;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 0;
	padding: 0 30px;
}
.product-details-button-box .compare-button button.compare:after {
	border-radius: 0px;
}

.product-details-button-box button i {
	position: relative;
	display: inline-block;
	float: left;
	font-size: 16px;
	line-height: 60px;
	padding-right: 10px;
}

.share-products-socials {
	position: relative;
	display: block;
	overflow: hidden;
	margin-top: 30px;
}
.share-products-socials h5 {
	position: relative;
	display: inline-block;
	float: left;
	color: #22254a;
	font-size: 14px;
	line-height: 40px;
	font-weight: 700;
	text-transform: capitalize;
}
.share-products-socials ul {
	position: relative;
	display: inline-block;
	float: left;
	margin-left: 20px;
}
.share-products-socials ul li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 20px;
}
.share-products-socials ul li:last-child {
	margin-right: 0;
}
.share-products-socials ul li a i {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	transition: all 500ms ease;
}
.share-products-socials ul li a i.fb {
	background: #3b5998;
	border-color: #3b5998;
	color: #ffffff;
}
.share-products-socials ul li a:hover i.fb {
	background: #1d376f;
}
.share-products-socials ul li a i.tw {
	background: #55acee;
	border-color: #55acee;
	color: #ffffff;
}
.share-products-socials ul li a:hover i.tw {
	background: #1e7dc5;
}
.share-products-socials ul li a i.pin {
	background: #cb2027;
	border-color: #cb2027;
	color: #ffffff;
}
.share-products-socials ul li a:hover i.pin {
	background: #a81218;
}
.share-products-socials ul li a i.lin {
	background: #007ab9;
	border-color: #007ab9;
	color: #ffffff;
}
.share-products-socials ul li a:hover i.lin {
	background: #01537d;
}

/*** 
=============================================
    Products Details Tab Area Css
=============================================
***/
.products-details-tab-area {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding: 113px 0 120px;
}

.product-tab-box {
	position: relative;
	display: block;
	overflow: hidden;
}
.product-tab-box .tab-btns {
	position: relative;
	display: block;
	text-align: center;
	z-index: 1;
}
.product-tab-box .tab-btns .tab-btn {
	position: relative;
	display: inline-block;
	float: none;
	margin: 0 -2px;
}
.product-tab-box .tab-btns .tab-btn span {
	position: relative;
	display: block;
	cursor: pointer;
	margin: 0;
	padding: 0px 29px 22px;
	color: #22254a;
	font-size: 16px;
	font-weight: 700;
	text-transform: capitalize;
	transition: all 500ms ease;
	font-family: 'Poppins', sans-serif;
}
.product-tab-box .tab-btns .tab-btn span:before {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	height: 3px;
	content: '';
	transform: scaleX(0);
	transform-origin: center;
	transform-style: preserve-3d;
	transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.22);
}
.product-tab-box .tab-btns .tab-btn.active-btn span:before {
	transform: scaleX(1);
}

.product-tab-box .tabs-content {
	position: relative;
	display: block;
}
.product-tab-box .tabs-content .tab {
	position: relative;
	background: #ffffff;
	padding: 44px 50px 40px;
	display: none;
}
.product-tab-box .tabs-content .tab.active-tab {
	display: block;
}

.product-descriptions-content {
	position: relative;
	display: block;
}
.product-descriptions-content .text {
	margin-bottom: 33px;
}
.product-descriptions-content .bottom-text {
	margin-top: 33px;
}

.product-tab-box .review-form {
	position: relative;
	display: block;
}
.product-tab-box .review-form .shop-page-title {
	padding-bottom: 29px;
}
.product-tab-box .review-form .shop-page-title .title {
	color: #22254a;
	font-size: 20px;
	line-height: 30px;
	font-weight: 600;
	margin: 0 0 4px;
	text-transform: capitalize;
	font-family: 'Poppins', sans-serif;
}
.product-tab-box .review-form .shop-page-title p {
	margin: 0;
}
.product-tab-box .review-form .shop-page-title p b {
	font-weight: 400;
}

.product-tab-box .review-form .add-rating-box {
	position: relative;
	display: block;
	overflow: hidden;
	margin-bottom: 20px;
}
.product-tab-box .review-form .add-rating-box .add-rating-title {
	position: relative;
	display: inline-block;
	float: left;
}
.product-tab-box .review-form .add-rating-box .add-rating-title p {
	position: relative;
	display: inline-block;
	float: left;
	line-height: 20px;
	margin: 0;
	text-transform: capitalize;
}
.product-tab-box .review-form .add-rating-box .review-box {
	position: relative;
	display: inline-block;
	float: left;
	padding-left: 20px;
}
.product-tab-box .review-form .add-rating-box .review-box ul li {
	position: relative;
	display: inline-block;
	float: left;
	line-height: 20px;
	margin-right: 5px;
}
.product-tab-box .review-form .add-rating-box .review-box ul li:last-child {
	margin-right: 0px;
}
.product-tab-box .review-form .add-rating-box .review-box ul li i {
	font-size: 14px;
	line-height: 20px;
	opacity: 1;
	transition: all 500ms ease 0s;
}
.product-tab-box .review-form .input-box p {
	font-size: 15px;
	line-height: 20px;
	font-weight: 500;
	margin: 0 0 5px;
}
.product-tab-box .review-form form input[type='text'],
.product-tab-box .review-form form input[type='email'],
.product-tab-box .review-form form textarea {
	position: relative;
	display: block;
	background: #ffffff;
	width: 100%;
	height: 50px;
	border: 1px solid #ededed;
	color: #252525;
	font-size: 15px;
	padding: 0 20px;
	margin-bottom: 30px;
	border-radius: 0px;
	transition: all 500ms ease;
}
.product-tab-box .review-form form textarea {
	height: 150px;
	padding: 10px 20px;
	margin-bottom: 40px;
}
.product-tab-box .review-form form button {
}
.product-tab-box .review-form form button i {
	position: relative;
	display: inline-block;
	padding-left: 10px;
}
.product-tab-box .review-form form button:hover {
}
.product-tab-box .review-form form input[type='text']:focus {
	border-color: #e1dddd;
}
.product-tab-box .review-form form input[type='email']:focus {
	border-color: #e1dddd;
}
.product-tab-box .review-form form textarea:focus {
	border-color: #e1dddd;
}

.review-box-holder {
	position: relative;
	display: block;
}
.review-box-holder .single-review-outer-box {
	position: relative;
	display: block;
	padding: 34px 39px 32px;
	border: 1px solid #ededed;
	margin-bottom: 20px;
}
.review-box-holder .single-review-box {
	position: relative;
	display: block;
	padding-left: 70px;
}
.review-box-holder .single-review-box .image-holder {
	position: absolute;
	left: 0;
	top: 5px;
}
.review-box-holder .single-review-box .image-holder img {
	width: 100%;
	border-radius: 50%;
}
.review-box-holder .single-review-box .text-holder {
	position: relative;
	display: block;
	padding-left: 25px;
}
.review-box-holder .single-review-box .text-holder .top {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0 0 12px;
}
.review-box-holder .single-review-box .text-holder .top .name h3 {
	color: #22254a;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	margin: 0 0 7px;
	text-transform: capitalize;
}
.review-box-holder .single-review-box .text-holder .top .name h3 span {
	color: #7e7e7e;
	font-size: 16px;
	font-weight: 400;
	text-transform: none;
	font-family: 'Open Sans', sans-serif;
}
.review-box-holder .single-review-box .text-holder .top .review-box ul {
	position: relative;
	display: block;
	overflow: hidden;
}
.review-box-holder .single-review-box .text-holder .top .review-box ul li {
	display: inline-block;
	float: left;
	margin-right: 4px;
}
.review-box-holder .single-review-box .text-holder .top .review-box ul li:last-child {
	margin-right: 0;
}
.review-box-holder .single-review-box .text-holder .top .review-box ul li i {
	font-size: 14px;
}
.review-box-holder .single-review-box .text-holder .text p {
	margin: 0;
}

/*** 
=============================================
    blog Area Css
=============================================
***/
#blog-area {
	position: relative;
	display: block;
}
.blog-style3-area {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding: 110px 0 120px;
}
.blog-style3-area .blog-posts {
	position: relative;
	display: block;
}

.single-blog-style2 {
	position: relative;
	display: block;
	margin-bottom: 40px;
}
.single-blog-style2 .img-holder {
	position: relative;
	display: block;
}
.single-blog-style2 .img-holder .inner {
	position: relative;
	display: block;
	overflow: hidden;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
}
.single-blog-style2 .img-holder .inner:before {
	position: absolute;
	content: '';
	top: 0px;
	left: 0px;
	width: 100%;
	height: 0%;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	opacity: 0;
	transform-style: preserve-3d;
	transform: translateY(100px, 50px);
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	z-index: 1;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
}
.single-blog-style2:hover .img-holder .inner:before {
	opacity: 1;
	height: 100%;
}
.single-blog-style2 .img-holder .inner img {
	width: 100%;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.4s ease;
	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
.single-blog-style2:hover .img-holder .inner img {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}

.single-blog-style2 .text-holder {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 50px 50px 50px;
	/* padding-right: 40px; */
	-webkit-box-shadow: 0px 3px 10px 2px rgba(20, 33, 43, 0.04);
	box-shadow: 0px 3px 10px 2px rgba(20, 33, 43, 0.04);
	border-radius: 0.5rem;
}
.single-blog-style2 .text-holder .categories {
	position: relative;
	display: inline-block;
	padding: 0 30px;
	border-radius: 6px;
}
.single-blog-style2 .text-holder .categories h5 {
	color: #ffffff;
	font-size: 14px;
	line-height: 30px;
	font-weight: 700;
}

.single-blog-style2 .text-holder .blog-title {
	color: #22254a;
	font-size: 34px;
	line-height: 46px;
	font-weight: 700;
	margin: 22px 0 12px;
}
.single-blog-style2 .text-holder .blog-title a {
	color: #22254a;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.single-blog-style2 .text-holder .meta-info {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-blog-style2 .text-holder .meta-info li {
	position: relative;
	display: inline-block;
	float: left;
	line-height: 24px;
	margin-right: 30px;
}
.single-blog-style2 .text-holder .meta-info li:last-child {
	margin-right: 0px;
}
.single-blog-style2 .text-holder .meta-info li span:before {
	position: relative;
	display: inline-block;
	padding-right: 6px;
	font-size: 14px;
	top: 1px;
}
.single-blog-style2 .text-holder .meta-info li a {
	color: #22254a;
	font-size: 14px;
	font-weight: 700;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.single-blog-style2 .text-holder .text {
	position: relative;
	display: block;
	margin: 15px 0px 33px;
}
.single-blog-style2 .text-holder .text p {
	color: #22254a;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
}
.single-blog-style2 .text-holder .bottom-box {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
.single-blog-style2 .text-holder .bottom-box .author {
	position: relative;
	display: block;
}
.single-blog-style2 .text-holder .bottom-box .author .image {
	width: 40px;
}
.single-blog-style2 .text-holder .bottom-box .author .image img {
	width: 100%;
	border-radius: 50%;
}
.single-blog-style2 .text-holder .bottom-box .author .image,
.single-blog-style2 .text-holder .bottom-box .author .name {
	display: table-cell;
	vertical-align: middle;
}

.single-blog-style2 .text-holder .bottom-box .author .name {
	position: relative;
	padding-left: 15px;
}
.single-blog-style2 .text-holder .bottom-box .author .name h4 {
	color: #22254a;
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
}
.single-blog-style2 .text-holder .bottom-box .readmore {
	position: relative;
	display: block;
}
.single-blog-style2 .text-holder .bottom-box .readmore a {
	color: #22254a;
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
}
.single-blog-style2 .text-holder .bottom-box .readmore a span {
	position: relative;
	display: inline-block;
	padding-right: 5px;
	top: 1px;
}

.blog-carousel-2.owl-nav-style-one.owl-theme .owl-nav {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
}
.blog-carousel-2.owl-nav-style-one.owl-theme .owl-nav [class*='owl-'] {
	background: transparent;
	border: 2px solid transparent;
	color: #ffffff;
}
.blog-carousel-2.owl-nav-style-one.owl-theme .owl-nav .owl-next {
	float: right;
}

.single-blog-style2 .outer-box {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 43px 50px 43px;
	padding-right: 40px;
	background-attachment: scroll;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 20px;
	z-index: 1;
}
.single-blog-style2 .outer-box:before {
	position: absolute;
	content: '';
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: -1;
}
.single-blog-style2 .inner-content {
	position: relative;
	display: block;
	overflow: hidden;
	padding-left: 110px;
}
.single-blog-style2 .inner-content .icon-holder {
	position: absolute;
	top: 5px;
	left: 0;
	color: #ffffff;
	font-size: 80px;
}
.single-blog-style2 .inner-content .text-holder {
	position: relative;
	display: block;
	background: transparent;
	padding: 0;
	padding-right: 0;
	box-shadow: none;
}
.single-blog-style2 .inner-content .text-holder .blog-title {
	margin-top: 0;
}
.single-blog-style2 .inner-content .text-holder .blog-title a {
	color: #ffffff;
}
.single-blog-style2 .inner-content .text-holder .meta-info li span:before {
	color: #ffffff;
}
.single-blog-style2 .inner-content .text-holder .meta-info li a {
	color: #ffffff;
}
.single-blog-style2 .inner-content .text-holder .meta-info li a:hover {
	color: #222222;
}

.single-blog-style2.style2instyle3 {
}
.single-blog-style2.style2instyle3 .text-holder {
	border-radius: 20px;
	padding-bottom: 43px;
}

.sidebar-wrapper {
	position: relative;
	display: block;
	max-width: 350px;
	width: 100%;
	float: right;
}
.sidebar-wrapper .single-sidebar {
	position: relative;
	display: block;
	overflow: hidden;
	margin-bottom: 40px;
	background: #ffffff;
	border-radius: 15px;
	padding: 35px 40px 40px;
}
.sidebar-wrapper .single-sidebar.last-child {
	margin: 0;
}
.sidebar-wrapper .single-sidebar .title {
	position: relative;
	display: block;
	text-align: left;
	padding-left: 32px;
	padding-bottom: 32px;
}
.sidebar-wrapper .single-sidebar .title:before {
	position: absolute;
	top: 5px;
	left: 0;
	width: 2px;
	height: 20px;
	content: '';
}
.sidebar-wrapper .single-sidebar .title h3 {
	color: #22254a;
	font-size: 20px;
	line-height: 30px;
	font-weight: 700;
	text-transform: capitalize;
}

.sidebar-about-me-box {
	position: relative;
	display: block;
}
.sidebar-about-me-box .image-box {
	position: relative;
	display: block;
	width: 140px;
	height: 140px;
	margin: 0 auto;
}
.sidebar-about-me-box .image-box img {
	width: 100%;
}
.sidebar-about-me-box .text-holder {
	position: relative;
	margin-top: 26px;
}
.sidebar-about-me-box .text-holder h3 {
	color: #22254a;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	margin: 0 0 12px;
}
.sidebar-about-me-box .text-holder p {
	color: #22254a;
	font-size: 14px;
	line-height: 26px;
	font-weight: 400;
	margin: 0;
}

.sidebar-social-link {
	position: relative;
	display: block;
}
.sidebar-social-link ul {
	overflow: hidden;
	margin-top: 15px;
}
.sidebar-social-link ul li {
	position: relative;
	display: inline-block;
	float: none;
	margin: 0 10px;
}
.sidebar-social-link ul li a {
	color: #b3bed3;
	font-size: 14px;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.sidebar-search-box {
	position: relative;
	display: block;
}
.sidebar-search-box form.search-form {
	position: relative;
	display: block;
	width: 100%;
}
.sidebar-search-box .search-form input[type='text'] {
	position: relative;
	display: block;
	width: 100%;
	height: 60px;
	border-radius: 14px;
	background-color: rgb(244, 242, 239);
	border: 0px solid #d3d3d3;
	color: #22254a;
	font-size: 14px;
	font-weight: 600;
	padding-left: 29px;
	padding-right: 65px;
	transition: all 500ms ease 0s;
	font-family: 'Open Sans', sans-serif;
}
.sidebar-search-box .search-form button {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 60px;
	height: 60px;
	color: #ffffff;
	font-size: 16px;
	line-height: 60px;
	display: block;
	border-radius: 14px;
	text-align: center;
	border: 0px solid #e1e1e1;
	transition: all 500ms ease 0s;
}
.sidebar-search-box .search-form button i {
	font-size: 16px;
}
.sidebar-search-box .search-form input[type='text']:focus {
	color: #000;
}
.sidebar-search-box .search-form input[type='text']:focus + button,
.sidebar-search-box .search-form button:hover {
	color: #ffffff;
}
.sidebar-search-box .search-form input::-webkit-input-placeholder {
	color: #22254a;
}
.sidebar-search-box .search-form input:-moz-placeholder {
	color: #22254a;
}
.sidebar-search-box .search-form input::-moz-placeholder {
	color: #22254a;
}
.sidebar-search-box .search-form input:-ms-input-placeholder {
	color: #22254a;
}

.single-sidebar .popular-feeds {
	position: relative;
	display: block;
}
.single-sidebar .popular-feeds li {
	position: relative;
	display: block;
	margin-bottom: 60px;
}
.single-sidebar .popular-feeds li:last-child {
	margin-bottom: 0;
}
.single-sidebar .popular-feeds li .inner {
	position: relative;
	display: block;
	min-height: 80px;
	padding-left: 80px;
}
.single-sidebar .popular-feeds li .img-box {
	position: absolute;
	top: 0;
	left: 0;
	width: 80px;
	height: 80px;
	overflow: hidden;
	border-radius: 50%;
}
.single-sidebar .popular-feeds li .img-box img {
	width: 100%;
	border-radius: 50%;
}
.single-sidebar .popular-feeds li .img-box .overlay-content {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
	border-radius: 0%;
	opacity: 0;
	transform: perspective(400px) scale(0);
	transform-origin: top;
	transition: all 0.5s ease-in-out 0s;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 2;
}
.single-sidebar .popular-feeds li:hover .img-box .overlay-content {
	opacity: 1;
	border-radius: 50%;
	transform: perspective(400px) scale(1);
	transition: all 0.3s ease-in-out 0.3s;
}
.single-sidebar .popular-feeds li .img-box .overlay-content a {
	color: #ffffff;
	font-size: 14px;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.single-sidebar .popular-feeds li .title-box {
	position: relative;
	display: block;
	padding-left: 20px;
}
.single-sidebar .popular-feeds li .title-box h4 {
	font-size: 15px;
	line-height: 22px;
	font-weight: 700;
	margin: 0px 0px 12px;
}
.single-sidebar .popular-feeds li .title-box h4 a {
	color: #22254a;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.single-sidebar .popular-feeds li .title-box h6 {
	color: #22254a;
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
}
.single-sidebar .popular-feeds li .title-box h6 span {
	font-size: 14px;
	font-weight: 400;
	margin-right: 7px;
}

.categorie-boxs {
	position: relative;
	display: block;
	overflow: hidden;
}
.categorie-boxs li {
	position: relative;
	display: block;
	margin-bottom: 10px;
}
.categorie-boxs li:last-child {
	margin-bottom: 0;
}
.categorie-boxs li a {
	position: relative;
	display: block;
	padding-left: 30px;
	background: #f9f9f9;
	color: #22254a;
	font-size: 14px;
	line-height: 50px;
	font-weight: 700;
	border-radius: 14px;
	transition: all 200ms linear;
	transition-delay: 0.1s;
	z-index: 1;
}
.categorie-boxs li a span {
	position: relative;
	display: inline-block;
	float: right;
	width: 50px;
	text-align: center;
	color: #ffffff;
	border-radius: 14px;
}
.categorie-boxs li a:before {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	border-radius: 14px;
	content: '';
	z-index: -1;
	transform: scaleY(0);
	transform-origin: left;
	transform-style: preserve-3d;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.categorie-boxs li.active a:before,
.categorie-boxs li a:hover:before {
	transform: scaleY(1);
}
.categorie-boxs li:hover a,
.categorie-boxs li.active a {
	color: #ffffff;
}

.sidebar-social-links {
	position: relative;
	display: block;
	overflow: hidden;
}
.sidebar-social-links li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 10px;
}
.sidebar-social-links li:last-child {
	margin-right: 0;
}
.sidebar-social-links li a {
	position: relative;
	display: block;
	width: 40px;
	height: 40px;
	background: #f9f9f9;
	color: #22254a;
	font-size: 14px;
	text-align: center;
	line-height: 40px;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.sidebar-social-links li a:hover {
	color: #ffffff;
}

.sidebar-twitter-feeds {
	position: relative;
	display: block;
	overflow: hidden;
	margin-top: -5px;
}
.sidebar-twitter-feeds li {
	position: relative;
	display: block;
	margin-bottom: 39px;
}
.sidebar-twitter-feeds li:last-child {
	margin-bottom: 0;
}
.sidebar-twitter-feeds li .inner {
	position: relative;
	display: block;
	padding-left: 32px;
}
.sidebar-twitter-feeds li .inner .icon {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 16px;
}
.sidebar-twitter-feeds li .inner .text {
	position: relative;
}
.sidebar-twitter-feeds li .inner .text p {
	font-size: 12px;
	line-height: 22px;
	margin: 0 0 13px;
}
.sidebar-twitter-feeds li .inner .text p a {
	color: #22254a;
}
.sidebar-twitter-feeds li .inner .text h5 {
	color: #22254a;
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
}

.single-sidebar .instagram {
	position: relative;
	display: block;
	overflow: hidden;
	margin-left: -7.5px;
	margin-right: -7.5px;
}
.single-sidebar .instagram li {
	position: relative;
	display: inline-block;
	float: left;
	width: 80px;
	min-height: 80px;
	margin: 0 7.5px 15px;
}
.single-sidebar .instagram li .img-box {
	position: relative;
	display: block;
	overflow: hidden;
}
.single-sidebar .instagram li .img-box img {
	width: 100%;
}
.single-sidebar .instagram li .img-box .overlay-content {
	position: absolute;
	top: 5px;
	left: 5px;
	bottom: 5px;
	right: 5px;
	opacity: 0;
	background-color: rgba(255, 72, 128, 0.8);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	transform: skew(0deg, 0deg) scale(1.2, 1.2);
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
.single-sidebar .instagram li:hover .img-box .overlay-content {
	opacity: 1;
	transform: translateY(0px);
}
.single-sidebar .instagram li .img-box .overlay-content a {
	position: relative;
	display: inline-block;
	color: #ffffff;
	font-size: 15px;
	line-height: 15px;
	transform: scale(0);
	transition: all 500ms ease 100ms;
}
.single-sidebar .instagram li:hover .img-box .overlay-content a {
	transform: scale(1);
	transition: all 700ms ease 600ms;
}

.single-sidebar .popular-tag {
	position: relative;
	display: block;
	overflow: hidden;
	margin-left: -5px;
	margin-right: -5px;
}
.single-sidebar .popular-tag li {
	position: relative;
	display: inline-block;
	float: left;
	margin: 0 5px 10px;
}
.single-sidebar .popular-tag li a {
	position: relative;
	display: block;
	background: #f9f9f9;
	padding: 0 19px;
	border: 0px solid #e4e4e4;
	color: #22254a;
	font-size: 12px;
	line-height: 30px;
	font-weight: 700;
	text-transform: capitalize;
	transition: all 0.4s ease-in-out 0.1s;
	font-family: 'Poppins', sans-serif;
}
.single-sidebar .popular-tag li a:hover {
	color: #ffffff;
}

.sidebar-add-banner-box {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	background-attachment: scroll;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	padding: 250px 0;
	display: flex;
	justify-content: center;
	align-content: center;
}
.sidebar-add-banner-box .inner {
	position: relative;
	display: inline-block;
	background: #ffffff;
	text-align: center;
	padding: 30px 40px;
	border-radius: 20px;
}
.sidebar-add-banner-box .inner h6 {
	color: #22254a;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
	margin: 0 0 10px;
}
.sidebar-add-banner-box .inner h3 {
	color: #22254a;
	font-size: 24px;
	line-height: 20px;
	font-weight: 700;
}

.styled-pagination {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	/* padding-top: 20px; */
}
.styled-pagination li {
	position: relative;
	display: inline-block;
	float: none;
	margin: 0 3.5px;
}
.styled-pagination li a {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 8px;
	background: #ffffff;
	border: 0px solid #ffffff;
	color: #22254a;
	font-size: 14px;
	line-height: 50px;
	font-weight: 700;
	text-align: center;
	text-transform: capitalize;
	transition: all 500ms ease;
	z-index: 1;
	font-family: 'Poppins', sans-serif;
}
.styled-pagination li:hover a,
.styled-pagination li.active a {
	color: #ffffff;
}

.styled-pagination li.prev a,
.styled-pagination li.next a {
	position: relative;
	line-height: 50px;
}
.styled-pagination li.prev a:hover,
.styled-pagination li.next a:hover {
	color: #ffffff;
}
.styled-pagination li.dotted a span {
	position: relative;
	display: inline-block;
	top: -3px;
}

.styled-pagination.gray li a {
	background: #f4f2ef;
}
.styled-pagination.gray li.prev a:hover,
.styled-pagination.gray li.next a:hover {
	color: #ffffff;
	background: #20234c;
}
.styled-pagination li:hover a,
.styled-pagination li.active a {
	color: #ffffff;
	background: #20234c;
}

/*** 
=============================================
    Blog Grid Area style
=============================================
***/
.blog-grid-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 120px 0 120px;
}
.blog-grid-area .single-blog-style1 {
	margin-bottom: 40px;
}

/*** 
=============================================
    Blog Single Area style
=============================================
***/
.blog-single-area {
	position: relative;
	display: block;
	background: #f4f2ef;
	padding: 110px 0 120px;
}
.blog-single-area .blog-posts {
	position: relative;
	display: block;
}
.blog-single-area .single-blog-style2 {
	margin: 0;
}
.blog-single-area .single-blog-style2 .text-holder {
	padding: 50px 50px 50px;
	padding-right: 50px;
	box-shadow: none;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}
.blog-details-single {
	position: relative;
	display: block;
}

.blog-details-image-1 {
	position: relative;
	display: block;
	padding-top: 10px;
}
.blog-details-image-1 img {
	width: 100%;
	border-radius: 20px;
}

.blog-details-text-1 {
	position: relative;
	display: block;
	margin: 43px 0px 0px;
	border-bottom: 1px solid #cdd8de;
	padding-bottom: 17px;
}
.blog-details-text-1 h2 {
	color: #22254a;
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
	margin-bottom: 23px;
}
.blog-details-text-1 p {
	color: #22254a;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
}

.blog-details-text-2 {
	position: relative;
	display: block;
	margin: 32px 0px 0px;
	border-bottom: 1px solid #cdd8de;
	padding-bottom: 17px;
}
.blog-details-text-2 h2 {
	color: #22254a;
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
	margin-bottom: 23px;
}
.blog-details-text-2 p {
	color: #22254a;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
}

.blog-details-text-3 {
	position: relative;
	display: block;
	margin: 32px 0px 0px;
}
.blog-details-text-3 h2 {
	color: #22254a;
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
	margin-bottom: 23px;
}
.blog-details-text-3 ul {
	position: relative;
	display: block;
	padding-left: 30px;
	overflow: hidden;
}
.blog-details-text-3 ul li {
	color: #22254a;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 6px;
	font-family: 'Poppins', sans-serif;
}
.blog-details-text-3 ul li:last-child {
	margin-bottom: 0;
}
.blog-details-text-3 ul li span {
	font-size: 16px;
	font-weight: 400;
	padding-right: 15px;
}

.blog-details-author-box {
	position: relative;
	display: block;
	border-radius: 20px;
	padding: 53px 50px 50px;
	margin-top: 32px;
}
.blog-details-author-box h6 {
	color: #ffffff;
	font-size: 14px;
	line-height: 24px;
	font-weight: 600;
}
.blog-details-author-box h2 {
	color: #ffffff;
	font-size: 28px;
	line-height: 38px;
	font-weight: 700;
	margin: 16px 0 0;
}
.blog-details-author-box .quote {
	position: absolute;
	bottom: 25px;
	right: 40px;
	color: rgba(255, 255, 255, 0.1);
	font-size: 180px;
}

.blog-details-text-4 {
	position: relative;
	display: block;
	padding-top: 34px;
}
.blog-details-text-4 p {
	color: #22254a;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
}

.blog-details-image-2 {
	position: relative;
	display: block;
	padding: 2px 0 19px;
}
.blog-details-image-2 .image-box {
	width: 255px;
}
.blog-details-image-2 .image-box,
.blog-details-image-2 .text-box {
	display: table-cell;
	vertical-align: middle;
}
.blog-details-image-2 .text-box {
	position: relative;
	padding-left: 30px;
}
.blog-details-image-2 .text-box p {
	margin: 0;
}

.tag-social-share-box {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	flex-wrap: wrap;
	padding-top: 22px;
	padding-bottom: 50px;
	border-bottom: 1px solid #eaeaea;
}
.tag-social-share-box .single-box {
	position: relative;
	display: block;
	overflow: hidden;
}
.tag-social-share-box .single-box .title {
	overflow: hidden;
	padding-bottom: 24px;
}
.tag-social-share-box .single-box .title.right {
	text-align: right;
}
.tag-social-share-box .single-box .title h3 {
	color: #22254a;
	font-size: 20px;
	line-height: 26px;
	font-weight: 700;
}
.tag-social-share-box .tag-list {
	position: relative;
	display: block;
	overflow: hidden;
}
.tag-social-share-box .tag-list li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 10px;
}
.tag-social-share-box .tag-list li:last-child {
	margin-right: 0;
}
.tag-social-share-box .tag-list li a {
	position: relative;
	display: inline-block;
	background: #f9f9f9;
	color: #121e2d;
	font-size: 12px;
	line-height: 30px;
	font-weight: 700;
	padding: 0 25px;
	border-radius: 8px;
	font-family: 'Poppins', sans-serif;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}
.tag-social-share-box .tag-list li a:hover {
	color: #ffffff;
}
.tag-social-share-box .social-share {
	position: relative;
	overflow: hidden;
}
.tag-social-share-box .social-share li {
	position: relative;
	display: inline-block;
	float: left;
	margin-right: 25px;
}
.tag-social-share-box .social-share li:last-child {
	margin-right: 0;
}
.tag-social-share-box .social-share li a {
	position: relative;
	display: inline-block;
	color: #b5becc;
	font-size: 18px;
	line-height: 30px;
	transition: all 200ms linear;
	transition-delay: 0.1s;
}

.blog-prev-next-option {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	flex-wrap: wrap;
	padding: 45px 0px 39px;
	border-bottom: 1px solid #eaeaea;
}
.blog-prev-next-option .single-box {
	position: relative;
	display: block;
	overflow: hidden;
	width: 40%;
}
.blog-prev-next-option .single-box.right {
	text-align: right;
}
.blog-prev-next-option .single-box p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
	margin: 0;
}
.blog-prev-next-option .single-box h2 {
	font-size: 28px;
	line-height: 40px;
	font-weight: 700;
	margin: 6px 0 0;
}
.blog-prev-next-option .single-box h2 a {
	color: #22254a;
}
.blog-prev-next-option .middle-box {
	width: 20%;
}
.blog-prev-next-option .middle-box .icon {
	position: relative;
	display: block;
	font-size: 40px;
	text-align: center;
	transition: all 200ms linear;
	transition-delay: 0.1s;
	line-height: 66px;
}
.blog-prev-next-option .middle-box .icon a {
	position: relative;
}

.blog-details-bottom-content {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 0px 50px 50px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}
.related-blog-post {
	position: relative;
	display: block;
}
.related-blog-post .inner-title {
	position: relative;
	display: block;
	padding-bottom: 32px;
}
.related-blog-post .inner-title h3 {
	color: #22254a;
	font-size: 26px;
	line-height: 34px;
	font-weight: 700;
}

.related-blog-post .single-blog-style1 .img-holder {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	overflow: hidden;
}
.related-blog-post .single-blog-style1 .text-holder {
}
.related-blog-post .single-blog-style1 .text-holder .text {
	padding-top: 10px;
}
.related-blog-post .single-blog-style1 .text-holder .text p {
	margin: 0;
}

.author-box-holder {
	position: relative;
	display: block;
	background: #f9f9f9;
	padding: 40px;
	padding-right: 30px;
	border-radius: 14px;
	margin-top: 50px;
}
.author-box-holder .inner {
	position: relative;
	display: block;
}
.author-box-holder .inner .img-box {
	width: 180px;
}
.author-box-holder .inner .img-box,
.author-box-holder .inner .text-box {
	display: table-cell;
	vertical-align: middle;
}
.author-box-holder .inner .text-box {
	position: relative;
	padding-left: 30px;
}
.author-box-holder .inner .text-box span {
	font-size: 12px;
	font-weight: 700;
}
.author-box-holder .inner .text-box h2 {
	color: #22254a;
	font-size: 36px;
	line-height: 46px;
	font-weight: 700;
	margin: 0px 0 8px;
}
.author-box-holder .inner .text-box p {
	color: #22254a;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	margin: 0;
}

.comment-box {
	position: relative;
	display: block;
	overflow: hidden;
	padding-top: 51px;
}
.comment-box .title {
	position: relative;
	display: block;
	padding-bottom: 31px;
}
.comment-box .title h3 {
	color: #22254a;
	font-size: 26px;
	line-height: 30px;
	font-weight: 700;
}

.comment-box .outer-box {
	position: relative;
	display: block;
}
.comment-box .single-comment {
	position: relative;
	display: block;
	border-bottom: 1px solid #eaeaea;
	margin-bottom: 30px;
}
.comment-box .single-comment.comment-reply {
	border-bottom: none;
	margin-left: 130px;
}
.comment-box .single-comment.comment-reply .single-comment-box .text-holder .text {
	border-bottom: 1px solid #eaeaea;
}

.comment-box .single-comment-box {
	position: relative;
	display: block;
	padding-left: 100px;
}
.comment-box .single-comment-box .img-holder {
	position: absolute;
	left: 0;
	top: 3px;
	width: 100px;
	height: 100px;
}
.comment-box .single-comment-box .img-holder img {
	width: 100%;
	border-radius: 0%;
}
.comment-box .single-comment-box .text-holder {
	position: relative;
	display: block;
	padding-left: 30px;
}
.comment-box .single-comment-box .text-holder .top {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
.comment-box .single-comment-box .text-holder .top .name {
	position: relative;
	display: block;
}
.comment-box .single-comment-box .text-holder .top .name h3 {
	color: #22254a;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	margin: 0 0 6px;
}
.comment-box .single-comment-box .text-holder .top .name h5 {
	color: #001d23;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
}
.comment-box .single-comment-box .text-holder .top .name h5 span:before {
	position: relative;
	display: inline-block;
	padding-right: 6px;
	font-size: 12px;
}
.comment-box .single-comment-box .text-holder .top .reply {
	position: relative;
	display: block;
}
.comment-box .single-comment-box .text-holder .top .reply a {
	color: #22254a;
	font-size: 14px;
	line-height: 16px;
	font-weight: 700;
}
.comment-box .single-comment-box .text-holder .top .reply a span:before {
	position: relative;
	display: inline-block;
	padding-right: 5px;
	font-size: 14px;
}
.comment-box .single-comment-box .text-holder .text {
	position: relative;
	display: block;
	padding: 20px 0 23px;
}
.comment-box .single-comment-box .text-holder .text p {
	color: #22254a;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	margin: 0;
}

.add-comment-box {
	position: relative;
	display: block;
	overflow: hidden;
	padding-top: 23px;
}
.add-comment-box .title {
	position: relative;
	display: block;
	padding-bottom: 34px;
}
.add-comment-box .title h3 {
	color: #22254a;
	font-size: 26px;
	line-height: 30px;
	font-weight: 700;
}
.add-comment-box #add-comment-form {
	position: relative;
	display: block;
	padding: 50px 50px;
	background: #f4f2ef;
	border-radius: 14px;
}
.add-comment-box #add-comment-form .input-box {
	position: relative;
	display: block;
}
.add-comment-box #add-comment-form .input-box .icon {
	position: absolute;
	top: 18px;
	right: 30px;
	font-size: 16px;
}
.add-comment-box #add-comment-form input[type='text'],
.add-comment-box #add-comment-form input[type='email'],
.add-comment-box #add-comment-form textarea {
	position: relative;
	display: block;
	background: #ffffff;
	border: 0px solid #eeeeee;
	width: 100%;
	height: 60px;
	color: #22254a;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	padding: 0 30px;
	padding-right: 60px;
	border-radius: 14px;
	margin-bottom: 20px;
	transition: all 500ms ease;
	font-family: 'Open Sans', sans-serif;
}

.add-comment-box #add-comment-form textarea {
	height: 150px;
	padding: 15px 30px;
	padding-right: 60px;
}
.add-comment-box #add-comment-form input[type='text']:focus {
	border-color: #22254a;
}
.add-comment-box #add-comment-form input[type='email']:focus {
	border-color: #22254a;
}
.add-comment-box #add-comment-form textarea:focus {
	border-color: #22254a;
}
.add-comment-box #add-comment-form .button-box {
	position: relative;
	display: block;
	padding-top: 10px;
}
.add-comment-box #add-comment-form .button-box button {
	line-height: 60px;
	padding: 0 40px;
	text-transform: uppercase;
}
.add-comment-box #add-comment-form .button-box button i:before {
	position: relative;
	display: inline-block;
	padding-right: 8px;
}

/*** 
=============================================
    Faq Page Area style
=============================================
***/
.faq-page-area {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 110px 0 120px;
}

/*** 
=============================================
    Error Page Area Style      
=============================================
***/
.error-page-area {
	position: relative;
	display: block;
	overflow: hidden;
	background: #ffffff;
	padding: 136px 0 150px;
}
.error-content {
	position: relative;
	display: block;
	z-index: 3;
}
.error-content h4 {
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.1em;
}
.error-content .title {
	position: relative;
	display: block;
	color: #393d72;
	font-size: 180px;
	line-height: 150px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 17px 0 12px;
	font-family: 'Poppins', sans-serif;
}
.error-content p {
	font-size: 18px;
	line-height: 28px;
	margin: 0 0 32px;
}
.error-content .btns-box {
}
.error-content .btns-box a {
	line-height: 60px;
}

/*** 
=============================================
    Google Map Area Css
=============================================
***/
.google-map-area {
	position: relative;
	display: block;
}
.contact-map-outer {
	position: relative;
	display: block;
	max-width: 100%;
	width: 100%;
}
.contact-map-outer .map-canvas {
	position: relative;
	width: 100%;
	height: 600px;
}

/*** 
=============================================
    RTL Page Css
=============================================
***/
/** Mainmenu Rtl Css **/

.rtl .main-menu {
	float: right;
}
.rtl .main-menu .navigation > li {
	float: right;
}
.rtl .main-menu .navigation > li:first-child {
	margin-right: 0px;
}
.rtl .main-menu .navigation > li:last-child {
	margin-right: 40px;
}
.rtl .main-menu .navigation > li > ul {
	left: auto;
	right: 0;
}
.rtl .main-menu .navigation > li > ul > li > a {
	text-align: right;
}
.rtl .main-menu .navigation > li > ul > li > a:before {
	display: none;
}
.rtl .main-menu .navigation > li > ul > li > ul > li a:before {
	display: none;
}

.rtl .main-menu .navigation > li > ul > li.dropdown > a:after {
	display: none;
}
.rtl .main-menu .navigation > li > ul > li > ul {
	left: auto;
	right: 100%;
}
.rtl .main-menu .navigation > li > ul > li > ul > li > a {
	text-align: right;
}
.rtl .sticky-header .main-menu .navigation > li {
	float: right;
}

.rtl.scroll-top {
	left: 20px;
	right: auto;
}

.rtl .single-featured-box .inner {
	padding-left: 0px;
	padding-right: 60px;
}
.rtl .single-featured-box .inner .icon {
	left: auto;
	right: 0;
}
.rtl .single-featured-box .inner .text {
	padding-left: 0px;
	padding-right: 20px;
}

.rtl .about-style1-content-box ul li .title {
	padding-left: 0px;
	padding-right: 20px;
}
.rtl .about-style1-content-box ul li .icon span:before {
	transform: rotateY(180deg);
}

.rtl .single-service-style1 .text-holder {
	padding-left: 20px;
	padding-right: 30px;
}

.rtl .priceing-plan-tabs .tab-button-column {
	left: 0;
	right: auto;
}
.rtl .single-priceing-plan-box ul li span {
	float: left;
}
.rtl .single-priceing-plan-box ul li span:before {
	line-height: 40px;
}

.rtl .single-testimonial-style1 .text-holder {
	padding-left: 0px;
	padding-right: 50px;
}

.rtl .single-blog-style1 .text-holder .meta-info li {
	float: right;
	padding-right: 0;
	margin-right: 0;
	margin-left: 15px;
}
.rtl .single-blog-style1 .text-holder .meta-info li:before {
	display: none;
}
.rtl .single-blog-style1 .text-holder .meta-info li span:before {
	padding-right: 0px;
	padding-left: 6px;
}
.rtl .blog-carousel.owl-nav-style-one.owl-theme .owl-nav {
	left: 0;
	right: auto;
}

.rtl .single-footer-widget.pdleft30 {
	padding-left: 0px;
}
.rtl .footer-logo .copy-right {
	border-left: 0px solid #322b6d;
	padding-left: 0px;
	margin-left: 0px;
	margin-right: 20px;
}
.rtl .footer-social-info a.fb {
	margin-left: 10px;
}

.rtl .header-contact-info ul li span:before {
	padding-right: 0px;
	padding-left: 10px;
}
.rtl .header-social-link ul li {
	float: right;
}
.rtl .header-social-link ul li:first-child {
	margin-right: 0px;
}
.rtl .header-social-link ul li:last-child {
	margin-right: 20px;
}

.rtl .header-right .logo {
	position: relative;
	display: block;
	float: right;
	padding: 22px 0;
}
.rtl .header-right .logo a {
	position: relative;
	display: inline-block;
}
.rtl .header-right .nav-outer {
	position: relative;
	display: block;
	float: left;
	padding-right: 80px;
}

.rtl .bar-box {
	float: left;
}
.rtl .header-left .button {
	float: left;
	padding: 25px 0;
	margin-left: 30px;
}

/*==============================================
   DatePicker Css
===============================================*/
#ui-datepicker-div.ui-widget-content {
	border: 1px solid #c5c5c5;
	background: #fff none repeat scroll 0 0;
	border: 1px solid #777;
	color: #252525;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	border-radius: 0px;
	width: 270px;
	padding: 5px;
	z-index: 10 !important;
}
#ui-datepicker-div.ui-datepicker .ui-datepicker-header {
	background: #151515 none repeat scroll 0 0;
	border: 2px solid #252525;
	border-radius: 0;
	color: #fff;
	font-weight: 700;
	padding: 5px 0;
	position: relative;
}

.ui-datepicker td a {
	color: #000000 !important;
	text-align: center;
	background-image: none !important;
	background: #f5f5f5 !important;
	border: 1px solid #f9f9f9 !important;
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	border: 1px solid #333333 !important;
	background: #333333 !important;
	color: #ffffff !important;
}
.ui-datepicker .ui-datepicker-prev {
	left: 5px;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	border-radius: 30%;
	height: 20px;
	position: absolute;
	top: 7px;
	width: 20px;
	transition: all 500ms ease;
	cursor: pointer;
}
.ui-datepicker .ui-datepicker-next {
	right: 5px;
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
	top: 7px;
	background: #fff;
	border: none;
}
.ui-datepicker table {
	border-collapse: collapse;
	font-size: 13px;
	margin: 0 0 0.4em;
	width: 100%;
	z-index: 99999999;
}
.ui-datepicker th {
	border: medium none;
	font-weight: 600;
	padding: 2px 3px;
	text-align: center;
}
.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-state-default:active {
	background: #43c3ea;
	color: #fff;
	border-color: #43c3ea;
}

/**
* maps 
====================================== */
.customMarker {
	position: absolute;
	cursor: pointer;
	background: #ff4880;
	width: 50px;
	height: 50px;
	/* -width/2 */
	margin-left: -50px;
	/* -height + arrow */
	margin-top: -110px;
	border-radius: 50%;
	padding: 0px;
}
.customMarker:after {
	content: '';
	position: absolute;
	bottom: -10px;
	left: 16px;
	border-width: 10px 10px 0;
	border-style: solid;
	border-color: #ff4880 transparent;
	display: block;
	width: 0;
}
.customMarker img {
	width: 44px;
	height: 44px;
	margin: 3px;
	border-radius: 50%;
}
.info-mark {
	display: grid;
	grid-template-columns: 60% 40%;
}
.info-mark .info-content {
	display: flex;
	justify-content: center;
	align-items: baseline;
	flex-direction: column;
	position: relative;
	margin-left: 5px;
}
.info-mark .img-cont {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.info-mark .img-cont img {
	width: 160px;
	height: 160px;
}
.logo a img {
	max-width: 80% !important;
}
